import { useRef } from "react";
import { NavLink } from "react-router-dom";
import classes from "./Header.module.css";

const Header = () => {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle(classes["responsive_nav"]);
  };

  return (
    <header className={classes.header}>
      <NavLink to="/" className={classes.logo}>
        <span>Betances Group</span>
      </NavLink>

      <button className={`${classes["nav-btn"]}`} onClick={showNavbar}>
        <span className={`${classes.icon} material-symbols-outlined`}>
          menu
        </span>
      </button>
      <nav ref={navRef}>
        <ul className={classes.navbar}>
          <li onClick={showNavbar}>
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? classes.active : "")}
            >
              Inicio
            </NavLink>
          </li>
          <li onClick={showNavbar}>
            <NavLink
              to="/servicios"
              className={({ isActive }) => (isActive ? classes.active : "")}
            >
              Servicios
            </NavLink>
          </li>
          <li onClick={showNavbar}>
            <NavLink
              to="/contacto"
              className={({ isActive }) => (isActive ? classes.active : "")}
            >
              Contacto
            </NavLink>
          </li>
        </ul>
        <button
          className={`${classes["nav-btn"]} ${classes["nav-close-btn"]}`}
          onClick={showNavbar}
        >
          <span className={`${classes.icon} material-symbols-outlined`}>
            close
          </span>
        </button>
      </nav>
    </header>
  );
};

export default Header;
