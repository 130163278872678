import { Route, Routes, Navigate } from "react-router-dom";

import classes from "./App.module.css";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";

import Header from "./components/header/Header";
import Home from "./components/Home/Home";
import Services from "./components/servicios/services";

import ReactGA from "react-ga";

const TRACKING_ID = "UA-253360855-1";

ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <div className={classes.app}>
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contacto" element={<Contact />} />
        <Route path="/servicios" element={<Services />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {/* <Home /> */}
      {/* <Contact /> */}
      <Footer />
    </div>
  );
}

export default App;
