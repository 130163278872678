import { useState } from "react";

import classes from "./form.module.css";
import FormSubmitButton from "../../UI/FormSubmitButton";

import useInput from "../../../hooks/useInput";
import useValidation from "../../../hooks/useValidation";
import contactService from "../../../services/ContactService";

import Spinner from "../../UI/Spinner";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";

const Form = (props) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { isEmailValid, isPhoneValid } = useValidation();

  const gaEventTracker = useAnalyticsEventTracker("Contact us");

  const {
    value: fullname,
    isValid: fullnameIsValid,
    hasError: fullnameHasError,
    valueChangeHandler: fullnameChangeHandler,
    inputBlurHandler: fullnameBlurHandler,
  } = useInput((value) => value.trim().length > 2);

  const {
    value: phone,
    isValid: phoneIsValid,
    hasError: phoneHasError,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
  } = useInput(isPhoneValid); //@TODO

  const {
    value: email,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(isEmailValid);

  const {
    value: message,
    isValid: messageIsValid,
    hasError: messageHasError,
    valueChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlurHandler,
  } = useInput((value) => value.trim().length > 2);

  const {
    value: subject,
    isValid: subjectIsValid,
    hasError: subjectHasError,
    valueChangeHandler: subjectChangeHandler,
    inputBlurHandler: subjectBlurHandler,
  } = useInput((value) => value.trim().length > 2);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setIsError(false);
    setIsSuccess(false);

    if (
      !fullnameIsValid ||
      !phoneIsValid ||
      !emailIsValid ||
      !messageIsValid ||
      !subjectIsValid
    ) {
      fullnameBlurHandler();
      phoneBlurHandler();
      emailBlurHandler();
      messageBlurHandler();
      subjectBlurHandler();
      return;
    }

    setIsLoading(true);

    contactService
      .send(email, fullname, subject, phone, message)
      .then(() => {
        setIsLoading(false);
        setIsSuccess(true);
        gaEventTracker("email-success");
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
        gaEventTracker("email-failed");
      });
  };

  const textInputClass = `${classes["input-text"]} ${classes["margin-top--40"]}`;

  const fullnameValidation = fullnameHasError ? classes["is-invalid"] : "";
  const phoneValidation = phoneHasError ? classes["is-invalid"] : "";
  const emailValidation = emailHasError ? classes["is-invalid"] : "";
  const messageValidation = messageHasError ? classes["is-invalid"] : "";
  const subjectValidation = subjectHasError ? classes["is-invalid"] : "";

  const fullnameInputWarning = fullnameHasError ? (
    <div className="text-danger">Proporcione un nombre valido.</div>
  ) : null;

  const phoneInputWarning = phoneHasError ? (
    <div className="text-danger">
      Proporcione un formato de numero correcto. <br />
      por ejemplo: xxx-xxx-xxxx
    </div>
  ) : null;

  const emailInputWarning = emailHasError ? (
    <div className="text-danger">
      Proporcione un correco electronico valido.
    </div>
  ) : (
    <small id="emailHelp" className="form-text text-muted">
      Nunca compartiremos su información con nadie más.
    </small>
  );

  const messageInputWarning = messageHasError ? (
    <div className="text-danger">Mensaje vacío no permitido.</div>
  ) : null;

  const subjectInputWarning = subjectHasError ? (
    <div className="text-danger">Tema vacío no permitido.</div>
  ) : null;

  return (
    <div
      className="col-lg-8 animated"
      data-show="startbox"
      data-show-delay="300"
      //   style="transform: translateY(0px); transition-duration: 500ms; opacity: 1;"
    >
      {isSuccess && (
        <div className={classes.success}>
          Gracias por ponerse en contacto con nosotros. Hemos recibido su
          mensaje y nos pondremos en contacto con usted lo antes posible.{" "}
          {/*Mientras tanto,
        no dudes en ponerte en contacto con nosotros si tienes cualquier otra
        pregunta o duda. */}
        </div>
      )}
      {isError && (
        <div className="text-danger">
          Lo sentimos, pero su mensaje no se ha enviado correctamente. <br />{" "}
          Inténtelo de nuevo más tarde.
        </div>
      )}
      <form onSubmit={onSubmitHandler}>
        <div className="row gy-40">
          <div className="col-12 col-md-6">
            <input
              className={`${textInputClass} ${fullnameValidation}`}
              type="text"
              placeholder="Tu Nombre *"
              onChange={fullnameChangeHandler}
              onBlur={fullnameBlurHandler}
            />
            {fullnameInputWarning}
          </div>
          <div className="col-12 col-md-6">
            <input
              className={`${textInputClass} ${emailValidation}`}
              type="email"
              placeholder="Su Correo Electrónico *"
              onChange={emailChangeHandler}
              onBlur={emailBlurHandler}
            />
            {emailInputWarning}
          </div>
          <div className="col-12 col-md-6">
            <input
              className={`${textInputClass} ${phoneValidation}`}
              type="tel"
              placeholder="Tu Teléfono *"
              onChange={phoneChangeHandler}
              onBlur={phoneBlurHandler}
            />
            {phoneInputWarning}
          </div>
          <div className="col-12 col-md-6">
            <input
              className={`${textInputClass}  ${subjectValidation}`}
              type="text"
              placeholder="Tema *"
              onChange={subjectChangeHandler}
              onBlur={subjectBlurHandler}
            />

            {subjectInputWarning}
          </div>

          <div className="col-12 mb-10">
            <textarea
              className={`${textInputClass} ${messageValidation}`}
              rows="2"
              placeholder="Mensaje *"
              onChange={messageChangeHandler}
              onBlur={messageBlurHandler}
            ></textarea>
            {messageInputWarning}
          </div>

          <div className="col-12 text-start text-end">
            {!isLoading && <FormSubmitButton name="enviar" />}
            {isLoading && <Spinner />}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
