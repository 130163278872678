const PiggyBank = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 300"
      width="406"
      height="306"
      className="illustration styles_illustrationTablet__1DWOa"
    >
      <g id="_68_piggy_bank_outline" data-name="#68_piggy_bank_outline">
        <rect
          x="92.3"
          y="218.41"
          width="38.74"
          height="10.03"
          rx="2.11"
          transform="translate(223.35 446.85) rotate(-180)"
          // fill="#ffbc0e"
          fill="#ff8c00"
        ></rect>
        <path
          d="M128.94,229.44H94.42a3.12,3.12,0,0,1-3.12-3.11v-5.81a3.12,3.12,0,0,1,3.12-3.11h34.52a3.12,3.12,0,0,1,3.11,3.11v5.81A3.11,3.11,0,0,1,128.94,229.44Zm-34.52-10a1.12,1.12,0,0,0-1.12,1.11v5.81a1.12,1.12,0,0,0,1.12,1.11h34.52a1.11,1.11,0,0,0,1.11-1.11v-5.81a1.11,1.11,0,0,0-1.11-1.11Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <rect
          x="96.44"
          y="208.38"
          width="38.74"
          height="10.03"
          rx="2.11"
          transform="translate(231.63 426.79) rotate(-180)"
          // fill="#ffbc0e"
          fill="#ff8c00"
        ></rect>
        <path
          d="M133.08,219.41H98.56a3.12,3.12,0,0,1-3.12-3.11v-5.81a3.12,3.12,0,0,1,3.12-3.11h34.52a3.12,3.12,0,0,1,3.11,3.11v5.81A3.12,3.12,0,0,1,133.08,219.41Zm-34.52-10a1.12,1.12,0,0,0-1.12,1.11v5.81a1.12,1.12,0,0,0,1.12,1.11h34.52a1.11,1.11,0,0,0,1.11-1.11v-5.81a1.11,1.11,0,0,0-1.11-1.11Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <rect
          x="100.66"
          y="198.35"
          width="38.74"
          height="10.03"
          rx="2.11"
          transform="translate(240.07 406.73) rotate(-180)"
          // fill="#ffbc0e"
          fill="#ff8c00"
        ></rect>
        <path
          d="M137.29,209.38H102.77a3.12,3.12,0,0,1-3.11-3.11v-5.81a3.11,3.11,0,0,1,3.11-3.11h34.52a3.12,3.12,0,0,1,3.12,3.11v5.81A3.12,3.12,0,0,1,137.29,209.38Zm-34.52-10a1.11,1.11,0,0,0-1.11,1.11v5.81a1.11,1.11,0,0,0,1.11,1.11h34.52a1.12,1.12,0,0,0,1.12-1.11v-5.81a1.12,1.12,0,0,0-1.12-1.11Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <rect
          x="87.25"
          y="188.32"
          width="38.74"
          height="10.03"
          rx="2.11"
          transform="translate(213.25 386.66) rotate(-180)"
          // fill="#ffbc0e"
          fill="#ff8c00"
        ></rect>
        <path
          d="M123.88,199.35H89.36a3.12,3.12,0,0,1-3.11-3.11v-5.81a3.11,3.11,0,0,1,3.11-3.11h34.52a3.12,3.12,0,0,1,3.12,3.11v5.81A3.12,3.12,0,0,1,123.88,199.35Zm-34.52-10a1.11,1.11,0,0,0-1.11,1.11v5.81a1.11,1.11,0,0,0,1.11,1.11h34.52a1.12,1.12,0,0,0,1.12-1.11v-5.81a1.11,1.11,0,0,0-1.12-1.11Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <rect
          x="92.3"
          y="178.28"
          width="38.74"
          height="10.03"
          rx="2.11"
          transform="translate(223.35 366.6) rotate(-180)"
          // fill="#ffbc0e"
          fill="#ff8c00"
        ></rect>
        <path
          d="M128.94,189.32H94.42a3.12,3.12,0,0,1-3.12-3.12v-5.8a3.12,3.12,0,0,1,3.12-3.12h34.52a3.12,3.12,0,0,1,3.11,3.12v5.8A3.12,3.12,0,0,1,128.94,189.32Zm-34.52-10a1.12,1.12,0,0,0-1.12,1.12v5.8a1.12,1.12,0,0,0,1.12,1.12h34.52a1.12,1.12,0,0,0,1.11-1.12v-5.8a1.12,1.12,0,0,0-1.11-1.12Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <path
          d="M135.31,145.87c-4.24,0-10.77-.77-15.38-4.85a8.18,8.18,0,0,1-1.66-2c-2.65-.35-6-1.72-9.62-5.47a1,1,0,0,1,1.44-1.39,16.08,16.08,0,0,0,7.24,4.64,13.59,13.59,0,0,1-.2-6.34c.67-3.24,2.37-5.61,4.55-6.32,1.29-.42,3.35-.45,5.82,1.78a6.07,6.07,0,0,1,1.77,6.09,9.54,9.54,0,0,1-6.83,6.74,9.92,9.92,0,0,1-1.68.31,5.66,5.66,0,0,0,.49.49c6.52,5.77,17.89,4.12,18,4.1a1,1,0,0,1,.3,2A31.94,31.94,0,0,1,135.31,145.87Zm-15.76-8.78a7.8,7.8,0,0,0,2.36-.3,7.61,7.61,0,0,0,5.41-5.28,4.11,4.11,0,0,0-1.16-4.14c-1.41-1.27-2.71-1.73-3.86-1.36-1.46.48-2.7,2.33-3.21,4.83A11.11,11.11,0,0,0,119.55,137.09Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <path
          d="M256.53,113.76s27-19.06,26.19-10.53-6.77,26-6.77,26"
          // fill="#68e1fd"
          fill="#00639d"
        ></path>
        <path
          d="M276,130.24a1,1,0,0,1-.33-.06,1,1,0,0,1-.62-1.27c.06-.17,6-17.46,6.73-25.77.07-.78-.18-.92-.26-1-2.36-1.37-14.24,5.24-24.37,12.4A1,1,0,0,1,256,113c3.61-2.56,21.87-15.17,26.52-12.51a2.74,2.74,0,0,1,1.25,2.87c-.76,8.56-6.58,25.53-6.83,26.25A1,1,0,0,1,276,130.24Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <path
          d="M237.78,228.44h8.84a6.92,6.92,0,0,0,6.86-6l3.53-25.8H227.4l3.53,25.8A6.91,6.91,0,0,0,237.78,228.44Z"
          // fill="#68e1fd"
          fill="#00639d"
        ></path>
        <path
          d="M246.62,229.44h-8.84a7.94,7.94,0,0,1-7.84-6.84l-3.53-25.8a1,1,0,0,1,.24-.79,1,1,0,0,1,.75-.35H257a1,1,0,0,1,.76.35,1,1,0,0,1,.24.79l-3.53,25.8A8,8,0,0,1,246.62,229.44Zm-18.07-31.78,3.37,24.66a5.94,5.94,0,0,0,5.86,5.12h8.84a5.94,5.94,0,0,0,5.87-5.12l3.37-24.66Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <path
          d="M167.81,228.44h8.84a6.92,6.92,0,0,0,6.86-6l3.52-25.8h-29.6l3.53,25.8A6.9,6.9,0,0,0,167.81,228.44Z"
          // fill="#68e1fd"
          fill="#00639d"
        ></path>
        <path
          d="M176.65,229.44h-8.84A8,8,0,0,1,160,222.6l-3.52-25.8a1,1,0,0,1,.24-.79,1,1,0,0,1,.75-.35H187a1,1,0,0,1,.76.35,1,1,0,0,1,.23.79l-3.52,25.8A8,8,0,0,1,176.65,229.44Zm-18.08-31.78L162,222.32a5.93,5.93,0,0,0,5.86,5.12h8.84a5.94,5.94,0,0,0,5.87-5.12l3.37-24.66Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <path
          d="M183.13,102h46.6a53.47,53.47,0,0,1,53.47,53.47v3.61a53.49,53.49,0,0,1-37,50.87l-1.71,12.51a6.92,6.92,0,0,1-6.86,6h-8.84a6.92,6.92,0,0,1-6.86-6l-1.35-9.91H183.13a53.42,53.42,0,0,1-7.31-.51l-1.43,10.42a6.92,6.92,0,0,1-6.85,6H158.7a6.92,6.92,0,0,1-6.86-6l-3.06-22.41a53.34,53.34,0,0,1-19.12-41v-3.61A53.47,53.47,0,0,1,183.13,102Z"
          // fill="#68e1fd"
          fill="#00639d"
        ></path>
        <path
          d="M237.66,229.44h-8.84A8,8,0,0,1,221,222.6l-1.23-9H183.13a55,55,0,0,1-6.46-.39l-1.29,9.44a7.94,7.94,0,0,1-7.84,6.84H158.7a8,8,0,0,1-7.85-6.84l-3-22a54.35,54.35,0,0,1-19.18-41.48v-3.61A54.53,54.53,0,0,1,183.13,101h46.6a54.53,54.53,0,0,1,54.47,54.47v3.61a54.32,54.32,0,0,1-37.07,51.62l-1.62,11.9A8,8,0,0,1,237.66,229.44ZM175.82,211H176a53.63,53.63,0,0,0,7.18.5h37.48a1,1,0,0,1,1,.87l1.36,9.9a5.93,5.93,0,0,0,5.86,5.12h8.84a5.94,5.94,0,0,0,5.87-5.12l1.71-12.51a1,1,0,0,1,.68-.81,52.34,52.34,0,0,0,36.28-49.92v-3.61A52.53,52.53,0,0,0,229.73,103h-46.6a52.53,52.53,0,0,0-52.47,52.47v3.61a52.39,52.39,0,0,0,18.76,40.2,1.05,1.05,0,0,1,.35.63l3.06,22.41a5.94,5.94,0,0,0,5.87,5.12h8.84a5.94,5.94,0,0,0,5.86-5.12l1.42-10.41a1,1,0,0,1,.39-.66A1,1,0,0,1,175.82,211Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <rect
          x="241.05"
          y="154.07"
          width="49.68"
          height="28.87"
          rx="13.57"
          transform="translate(531.78 337.01) rotate(-180)"
          // fill="#68e1fd"
          fill="#00639d"
        ></rect>
        <path
          d="M277.16,183.94H254.62a14.59,14.59,0,0,1-14.57-14.57v-1.73a14.59,14.59,0,0,1,14.57-14.57h22.54a14.59,14.59,0,0,1,14.57,14.57v1.73A14.59,14.59,0,0,1,277.16,183.94Zm-22.54-28.87a12.59,12.59,0,0,0-12.57,12.57v1.73a12.59,12.59,0,0,0,12.57,12.57h22.54a12.59,12.59,0,0,0,12.57-12.57v-1.73a12.59,12.59,0,0,0-12.57-12.57Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <path
          d="M246.32,115.69s-9-23.59-13.65-23.59S221,122.21,221,122.21"
          // fill="#68e1fd"
          fill="#00639d"
        ></path>
        <path
          d="M221,123.21l-.23,0A1,1,0,0,1,220,122c0-.07,1.79-7.64,4.11-15.12,4.34-14,6.85-15.76,8.55-15.76,5,0,12.42,18.55,14.59,24.23a1,1,0,0,1-.58,1.29,1,1,0,0,1-1.29-.58c-4.21-11-10.27-22.94-12.72-22.94-1.87,0-6.62,11.79-10.71,29.34A1,1,0,0,1,221,123.21Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        {/* <circle cx="273.79" cy="168.51" r="4.16" fill="#68e1fd"
        fill="#00639d"></circle> */}
        <path
          d="M273.79,173.67a5.17,5.17,0,1,1,5.17-5.16A5.17,5.17,0,0,1,273.79,173.67Zm0-8.33a3.17,3.17,0,1,0,3.17,3.17A3.17,3.17,0,0,0,273.79,165.34Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        {/* <circle cx="257.99" cy="168.51" r="4.16" fill="#68e1fd"
        fill="#00639d"></circle> */}
        <path
          d="M258,173.67a5.17,5.17,0,1,1,5.16-5.16A5.17,5.17,0,0,1,258,173.67Zm0-8.33a3.17,3.17,0,1,0,3.16,3.17A3.17,3.17,0,0,0,258,165.34Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <path
          d="M170.84,109.76a2.61,2.61,0,0,0,2.61,2.61h39.61a2.6,2.6,0,0,0,2.6-2.61,2.57,2.57,0,0,0-.76-1.83,2.6,2.6,0,0,0-1.66-.76h-40A2.6,2.6,0,0,0,170.84,109.76Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <path
          d="M213.06,113.37H173.45a3.6,3.6,0,0,1-.25-7.2h39.86l.32,0a3.44,3.44,0,0,1,2.22,1,3.55,3.55,0,0,1,1.06,2.54A3.61,3.61,0,0,1,213.06,113.37Zm-39.61-5.21h-.12a1.6,1.6,0,0,0,.12,3.2h39.61a1.6,1.6,0,0,0,.11-3.2H173.45Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <ellipse
          cx="271.71"
          cy="142.47"
          rx="2.08"
          ry="4.35"
          // fill="#093f68"
          fill="#000000"
        ></ellipse>
        <ellipse
          cx="253.83"
          cy="142.47"
          rx="2.08"
          ry="4.35"
          // fill="#093f68"
          fill="#000000"
        ></ellipse>
        <path
          d="M136.93,166.22a1,1,0,0,1-1-.81c-5.38-28.65,16.59-43.88,16.82-44a1,1,0,0,1,1.38.27,1,1,0,0,1-.26,1.39c-.21.14-21.11,14.67-16,42a1,1,0,0,1-.8,1.16Z"
          fill="#fff"
        ></path>
        <circle
          cx="217.93"
          cy="71.78"
          r="18.01"
          // fill="#ffbc0e"
          fill="#ff8c00"
        ></circle>
        <path
          d="M217.92,90.8a19.07,19.07,0,1,1,7.2-1.43A19,19,0,0,1,217.92,90.8Zm0-36A17.08,17.08,0,0,0,202.24,65.2h0a17,17,0,1,0,15.69-10.44ZM201.32,64.82h0Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <g id="lUQdTl">
          <path
            d="M213.87,58.22c.36.87.73,1.73,1.08,2.6.07.17.15.2.33.17a8.42,8.42,0,0,1,4.14.16,6,6,0,0,1,3.41,2.61c.21.34.4.7.61,1.07l-4,1.68a2.91,2.91,0,0,0-1.29-1.41,12.67,12.67,0,0,0-1.47-.49l0,.09,2.1,5,.47-.08a15.34,15.34,0,0,1,3.58-.31A5,5,0,0,1,227.09,72a6.52,6.52,0,0,1-1.27,8.26c-.63.57-1.34,1.06-2,1.61l.82,2-3.7,1.55-.84-2c-.83.11-1.61.26-2.4.31a6.79,6.79,0,0,1-4.33-1.09,6.63,6.63,0,0,1-2.39-3l4-1.66a3.38,3.38,0,0,0,3.64,1.92q-1.12-2.7-2.25-5.37c-.84.1-1.68.21-2.52.29a6.19,6.19,0,0,1-3-.33,4.94,4.94,0,0,1-2.32-2,7,7,0,0,1-1.2-3.54,6.24,6.24,0,0,1,2-4.9,18.09,18.09,0,0,1,1.78-1.35,2.1,2.1,0,0,1,.22-.15l-1.13-2.7Zm8.41,20a3.39,3.39,0,0,0,1.36-1.42,2.32,2.32,0,0,0-.84-3,4.53,4.53,0,0,0-2.45-.22ZM212.74,65.9a10.92,10.92,0,0,0-1.12,1.32,2.14,2.14,0,0,0,1,3.1,4.62,4.62,0,0,0,2,.05Z"
            fill="#fff"
          ></path>
        </g>
        <path
          d="M209.78,110.29a18.88,18.88,0,0,0,.77-2,18.09,18.09,0,0,0,.62-2.64,17.5,17.5,0,0,0,.22-2.82,18,18,0,0,0-36,0,18.72,18.72,0,0,0,.21,2.82,18.12,18.12,0,0,0,.63,2.64,16.86,16.86,0,0,0,.76,2"
          // fill="#ffbc0e"
          fill="#ff8c00"
        ></path>
        <path
          d="M209.78,111.29a1,1,0,0,1-.4-.08,1,1,0,0,1-.51-1.32,17.67,17.67,0,0,0,.72-1.88,15.58,15.58,0,0,0,.59-2.5,16.65,16.65,0,0,0,.21-2.66,17,17,0,1,0-33.81,2.67,18.27,18.27,0,0,0,.59,2.49,19.13,19.13,0,0,0,.72,1.88,1,1,0,1,1-1.82.81,18.86,18.86,0,0,1-.81-2.09,20.7,20.7,0,0,1-.66-2.78,19.54,19.54,0,0,1-.22-3,19,19,0,0,1,38,0,19.31,19.31,0,0,1-.23,3,19.1,19.1,0,0,1-.66,2.79,20.87,20.87,0,0,1-.8,2.09A1,1,0,0,1,209.78,111.29Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <g id="lUQdTl-2" data-name="lUQdTl">
          <path
            d="M202.25,106.78a5.55,5.55,0,0,0-.24-1.12,4.88,4.88,0,0,0-2.72-3,15.31,15.31,0,0,0-3.42-1.09l-.47-.11V96l.08-.06a12.62,12.62,0,0,1,1.16,1,2.86,2.86,0,0,1,.65,1.8h4.36c-.06-.42-.09-.82-.15-1.22a6,6,0,0,0-2.14-3.73,8.58,8.58,0,0,0-3.75-1.75c-.18,0-.23-.09-.23-.28V89h-4v2.92l-.26,0a19.76,19.76,0,0,0-2.17.57,6.18,6.18,0,0,0-3.76,3.72,6.9,6.9,0,0,0-.27,3.74,5,5,0,0,0,1.38,2.7,6.33,6.33,0,0,0,2.63,1.46c.8.25,1.62.47,2.43.7v5.44h4v-4.55a4.57,4.57,0,0,1,2.17,1.15,2,2,0,0,1,.41,1.42,2.33,2.33,0,0,1-.78,1.64,2.73,2.73,0,0,1-.5.34h5.13a5.87,5.87,0,0,0,.48-2A7.15,7.15,0,0,0,202.25,106.78Zm-12.67-7.1a2.14,2.14,0,0,1,.25-3.27,11.48,11.48,0,0,1,1.53-.77v4.84A4.65,4.65,0,0,1,189.58,99.68Zm-.69,8.63a4.84,4.84,0,0,1-.16-.81h-4.28a7.09,7.09,0,0,0,0,.81,6.54,6.54,0,0,0,.49,2h5.42A3.13,3.13,0,0,1,188.89,108.31Z"
            fill="#fff"
          ></path>
        </g>
        <rect
          x="265.89"
          y="218.41"
          width="38.74"
          height="10.03"
          rx="2.11"
          transform="translate(570.53 446.85) rotate(-180)"
          // fill="#ffbc0e"
          fill="#ff8c00"
        ></rect>
        <path
          d="M302.52,229.44H268a3.11,3.11,0,0,1-3.11-3.11v-5.81a3.12,3.12,0,0,1,3.11-3.11h34.52a3.12,3.12,0,0,1,3.12,3.11v5.81A3.12,3.12,0,0,1,302.52,229.44Zm-34.52-10a1.11,1.11,0,0,0-1.11,1.11v5.81a1.11,1.11,0,0,0,1.11,1.11h34.52a1.12,1.12,0,0,0,1.12-1.11v-5.81a1.12,1.12,0,0,0-1.12-1.11Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <rect
          x="271.29"
          y="208.38"
          width="38.74"
          height="10.03"
          rx="2.11"
          transform="translate(581.33 426.79) rotate(-180)"
          // fill="#ffbc0e"
          fill="#ff8c00"
        ></rect>
        <path
          d="M307.93,219.41H273.41a3.12,3.12,0,0,1-3.12-3.11v-5.81a3.12,3.12,0,0,1,3.12-3.11h34.52a3.12,3.12,0,0,1,3.11,3.11v5.81A3.12,3.12,0,0,1,307.93,219.41Zm-34.52-10a1.12,1.12,0,0,0-1.12,1.11v5.81a1.12,1.12,0,0,0,1.12,1.11h34.52A1.11,1.11,0,0,0,309,216.3v-5.81a1.11,1.11,0,0,0-1.11-1.11Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
        <path
          d="M318.44,229.44H81.56a1,1,0,0,1,0-2H318.44a1,1,0,0,1,0,2Z"
          // fill="#093f68"
          fill="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default PiggyBank;
