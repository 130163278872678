const Bg_Logo_White = () => {
  // width = "409px";
  // height = "513px";

  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 487.5 512.58"
      height="409"
      width="513"
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path
            fill="#ffffff"
            d="m114.87,407.8c2.35,3.11,6.65,6.16,6.75,9.34.61,21.31.31,42.64.31,65-3.7-1.55-7.9-2.68-11.43-4.89-16.26-10.17-31.21-21.9-45.02-35.39-15.74-15.37-28.17-32.85-39.72-51.41-9.42-15.13-13.31-31.84-18.59-48.38C-3.15,309.71.78,276.56.54,243.71-.05,165.17.3,86.63.27,8.09.27.5,1.43-.67,8.82.29c24.31,3.14,45.31,17.79,50.78,43.3,1.82,8.48,3.5,17.15,3.75,25.77.58,19.8.22,39.62.28,59.43,0,1.95.3,3.91.51,6.36,27.89-24.9,57.61-44.98,92.45-57.22,18.31-6.44,36.76-10.77,56.05-12.7,22.43-2.24,44.59-1.03,66.91,1.4,23.38,2.54,45.87,8.47,67.3,17.55,13.9,5.89,26.63,14.61,39.73,22.33,17.3,10.2,31.54,24.2,44.72,39.01,11.82,13.28,22.7,27.49,29.94,44.08,4.21,9.65,10.14,18.73,13.08,28.72,4.63,15.74,7.56,31.99,10.91,48.09,4.12,19.78,2.11,39.87-1.89,59.01-5.61,26.79-13.85,53.22-30.41,75.88-7.54,10.32-15.45,20.38-23.39,30.4-1.02,1.29-3.27,1.61-4.95,2.38-.13-1.57-.37-3.14-.37-4.7-.03-12.73,0-25.46-.01-38.2,0-34.43.41-68.87-.17-103.3-.34-20.61-3.32-41.19-12.08-59.98-9.21-19.76-20.55-38.49-36.56-53.76-28.21-26.93-62.09-43.12-100.01-50.41-14.04-2.7-28.8-3.62-43.06-2.7-13.77.89-27.28,5.49-40.99,7.93-17.61,3.13-33.46,10.56-48.12,20.21-11.94,7.87-23.8,16.37-33.9,26.4-9.28,9.21-16.45,20.64-24.02,31.46-7.63,10.91-12.95,22.9-16.07,36.02-6.23,26.13-9.79,52.24-3.16,78.84,1.37,5.51,2.42,11.1,3.62,16.65,5.83,14.87,5.26,14.07,8.42,19.16,12.22,19.71,6.03,10.26,20.62,31.56,18.19,20.84,0,0,16.16,18.52Z"
          />
          <path
            fill="#ffffff"
            d="m182.38,449.07c9.73,1.07,18.7,1.76,27.59,3.1,18.08,2.73,36.44,6.04,53.99-.64,12.39-4.71,23.62-11.77,30.71-24.52,5.39-9.67,8.7-19.15,8.28-30.16-.2-5.13-2.08-6.78-7-5.35-10.56,3.07-21,6.93-31.78,8.71-9.73,1.6-19.86,1.29-29.78.96-6.6-.22-13.14-2.08-19.74-2.96-15.24-2.03-28.53-8.69-41.11-17.09-14.61-9.75-26.71-21.73-36.32-36.72-11.12-17.34-17.72-35.76-16.58-56.24,1.27-22.92,7.6-44.24,21.62-63.2,16.32-22.07,37.42-37.09,63.52-44.19,17.68-4.81,35.91-5.92,54.56-3.86,26.38,2.92,48.53,13.76,68.14,30.91,13.89,12.15,24.44,26.92,30.58,44.14,3.6,10.09,5.73,21.22,5.92,31.93.7,38.67.41,77.37.27,116.05-.1,27.82-9.5,52.38-28.34,72.71-12.19,13.15-26.41,23.56-43.53,30.5-21.99,8.92-44.19,11.08-67.37,8.26-11.3-1.37-22.68-2.13-34.04-2.88-4.92-.32-9.37-1.53-9.48-7.04-.34-17.17-.12-34.34-.12-52.43Zm59.89-215.47c-10.79.94-22.01,2.62-32.26,8.96-9.94,6.14-16.89,14.85-22.05,24.86-7.06,13.71-7.44,28.76-.38,41.96,8.24,15.41,20.15,26.85,39.03,31.45,12.44,3.04,23.03,2.85,34.91-.49,20.81-5.85,33.37-19.55,39.56-39.33,5.02-16.03.7-30.57-9.39-43.89-12.32-16.26-28.98-22.69-49.42-23.52Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Bg_Logo_White;
