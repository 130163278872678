import axios from "axios";

const API_URL = "https://betancesgroup.com/api/contact/";

const send = (from, name, subject, phone, content) => {
  return axios
    .post(API_URL + "send", {
      from,
      name,
      subject,
      phone,
      content,
    })
    .then((response) => response.data);
};

const functions = {
  send,
};

export default functions;
