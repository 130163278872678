import classes from "./ClearBlueButton.module.css";

const ClearBlueButton = ({ name, onClick }) => {
  return (
    <button className={classes.button} onClick={onClick}>
      <span>{name}</span>
    </button>
  );
};

export default ClearBlueButton;
