const Location = () => {
  return (
    <svg
      className="text-accent-1"
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="17"
      fill="none"
    >
      <path
        fill="#ff8c00"
        fillRule="evenodd"
        d="M7.5 17S15 12.364 15 6.955c0-1.845-.79-3.614-2.197-4.918C11.397.733 9.49 0 7.5 0 5.51 0 3.603.733 2.197 2.037.79 3.34 0 5.11 0 6.955 0 12.364 7.5 17 7.5 17ZM10 7.286c0 1.341-1.12 2.428-2.5 2.428S5 8.627 5 7.286c0-1.342 1.12-2.429 2.5-2.429S10 5.944 10 7.286Z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Location;
