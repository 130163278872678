import { NavLink } from "react-router-dom";
import BetancesGroupLogoColor from "../../images/Betances_group_logo_color";
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <div className="container">
      <footer className="row row-cols-5 py-5 my-5 border-top">
        <div className="col">
          <NavLink
            to="/"
            className="d-flex align-items-center mb-3 link-dark text-decoration-none"
          >
            <BetancesGroupLogoColor />
          </NavLink>
          <p className="text-muted">© 2023</p>
        </div>

        <div className="col"></div>

        <div className="col">
          <h5>Enlaces rápidos</h5>
          <ul className={`${classes.links} nav flex-column`}>
            <li className="nav-item mb-2">
              <NavLink to="/">Inicio</NavLink>
            </li>
            <li className="nav-item mb-2">
              <NavLink to="/servicios" className="p-0 text-muted">
                Servicos
              </NavLink>
            </li>
            <li className="nav-item mb-2">
              <NavLink to="/contacto" className="p-0 text-muted">
                Contacto
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="col">
          <h5>Enlaces útiles</h5>
          <ul className={`${classes.links} nav flex-column`}>
            <li className="nav-item mb-2">
              <a href="https://www.irs.gov" className="nav-link p-0 text-muted">
                Internal Revenue Service (IRS)
              </a>
            </li>
            <li className="nav-item mb-2">
              <a
                href="https://www.tax.ny.gov"
                className="nav-link p-0 text-muted"
              >
                Department of Taxation and Finance
              </a>
            </li>
            <li className="nav-item mb-2">
              <a
                href="https://www.ups.com/track"
                className="nav-link p-0 text-muted"
              >
                UPS
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
