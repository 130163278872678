const TeamPresentation = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 300"
      // width="606"
      // height="506"
      className="illustration styles_illustrationTablet__1DWOa"
    >
      <g
        id="_566_team_presentation_flatline"
        data-name="#566_team_presentation_flatline"
      >
        <path
          d="M138.46,249.19s-5.47,8.51-6.54,16.62h51.69a12.1,12.1,0,0,0-6.84-6.46c-5.18-2-16.42-2.93-18.27-5a7.22,7.22,0,0,1-1.47-5.18Z"
          fill="#231f20"
        ></path>
        <path
          d="M183.61,266.25H131.92a.49.49,0,0,1-.34-.15.44.44,0,0,1-.1-.35c1.07-8.11,6.56-16.71,6.61-16.8a.43.43,0,0,1,.37-.2H157a.41.41,0,0,1,.33.15.47.47,0,0,1,.11.34,6.85,6.85,0,0,0,1.35,4.83c1,1.08,5.21,1.91,9.31,2.73a61.13,61.13,0,0,1,8.8,2.14,12.45,12.45,0,0,1,7.1,6.71.43.43,0,0,1-.06.41A.42.42,0,0,1,183.61,266.25Zm-51.19-.88h50.5a11.84,11.84,0,0,0-6.3-5.61,62.31,62.31,0,0,0-8.66-2.1c-4.63-.91-8.63-1.71-9.79-3a7.32,7.32,0,0,1-1.61-5H138.71C137.9,250.93,133.52,258.29,132.42,265.37Z"
          fill="#231f20"
        ></path>
        <polygon
          points="139.83 243.62 138.46 249.19 157.03 249.19 157.62 242.25 139.83 243.62"
          fill="#fff"
        ></polygon>
        <path
          d="M157,249.69H138.46a.5.5,0,0,1-.48-.62l1.37-5.57a.49.49,0,0,1,.44-.38l17.79-1.37a.47.47,0,0,1,.39.15.48.48,0,0,1,.15.39l-.59,6.94A.5.5,0,0,1,157,249.69Zm-17.93-1h17.47l.5-5.9-16.84,1.3Z"
          fill="#231f20"
        ></path>
        <line x1="109.18" y1="193.2" x2="121.17" y2="265.81" fill="#fff"></line>
        <path
          d="M121.17,266.31a.51.51,0,0,1-.5-.42l-12-72.61a.49.49,0,0,1,.41-.57.49.49,0,0,1,.57.41l12,72.61a.5.5,0,0,1-.41.57Z"
          fill="#231f20"
        ></path>
        <line x1="95.97" y1="193.2" x2="83.74" y2="265.81" fill="#fff"></line>
        <path
          d="M83.74,266.31h-.08a.49.49,0,0,1-.41-.58l12.22-72.6a.51.51,0,0,1,.58-.41.49.49,0,0,1,.41.57L84.23,265.89A.5.5,0,0,1,83.74,266.31Z"
          fill="#231f20"
        ></path>
        <path
          d="M155.25,177.61s9.06-10.73,14.31-6.64S159,245.54,159,245.54H137.89s2.15-43.66,4.68-52S155.25,177.61,155.25,177.61Z"
          fill="#231f20"
        ></path>
        <path
          d="M159,246H137.89a.43.43,0,0,1-.32-.14.38.38,0,0,1-.12-.32c.08-1.79,2.18-43.82,4.7-52.1s12-15.53,12.8-16.14c.7-.82,9.47-10.87,14.88-6.66s-8.8,67.77-10.43,75A.45.45,0,0,1,159,246Zm-20.62-.88h20.27c6.33-28.23,14.35-70.92,10.67-73.78-4.86-3.79-13.61,6.47-13.7,6.57l-.07.07c-.1.07-10.06,7.62-12.53,15.71C140.64,201.4,138.59,240.39,138.35,245.1Zm16.9-67.49h0Z"
          fill="#231f20"
        ></path>
        <path
          d="M107.65,242.78a14.69,14.69,0,0,0-3.16,5.89h18.63l2.42-5.89Z"
          fill="#fff"
        ></path>
        <path
          d="M123.12,249.17H104.49a.51.51,0,0,1-.39-.19.53.53,0,0,1-.1-.42,15,15,0,0,1,3.28-6.12.51.51,0,0,1,.37-.16h17.89a.51.51,0,0,1,.42.22.49.49,0,0,1,0,.47l-2.42,5.89A.5.5,0,0,1,123.12,249.17Zm-18-1h17.66l2-4.89H107.88A14.56,14.56,0,0,0,105.13,248.17Z"
          fill="#231f20"
        ></path>
        <path
          d="M89.32,177.24s-.72,7.85,7.49,13.41,36,2.65,36,2.65l-27.52,49.28,23.59,1.94s33.39-55.08,32.91-64.26-14.25-10.87-14.25-10.87H91.86Z"
          fill="#231f20"
        ></path>
        <path
          d="M128.86,245h0L105.24,243a.45.45,0,0,1-.36-.23.42.42,0,0,1,0-.42L132,193.82c-4.8.45-27.9,2.29-35.44-2.81-8.33-5.63-7.68-13.73-7.68-13.81s0-.06,0-.09l2.54-7.85a.45.45,0,0,1,.42-.31h55.66c.63.08,14.19,1.85,14.69,11.29.49,9.21-31.6,62.25-33,64.51A.44.44,0,0,1,128.86,245ZM106,242.2l22.64,1.85c2.36-3.91,33.16-55.13,32.7-63.76s-13.73-10.44-13.86-10.46H92.18l-2.42,7.5c0,.63-.27,7.83,7.3,12.95,8,5.4,35.41,2.61,35.69,2.58a.43.43,0,0,1,.41.2.45.45,0,0,1,0,.46Z"
          fill="#231f20"
        ></path>
        <path
          d="M87,150.68c.18,1.29.3,2.58.37,3.86.44,7.2-.31,14.51,1.09,21.58a6.56,6.56,0,0,0,1.52,3.48A6.38,6.38,0,0,0,92.56,181c5.75,1.78,11.91,1.52,17.84,2.58s12.14,4,14.37,9.58a1.79,1.79,0,0,1-1.11,2.81c-4.48,1.73-10.12.59-14.85.57-6.62,0-13.25-.27-19.85-.72a18.56,18.56,0,0,1-6.63-1.32c-7.6-3.56-9.59-15.24-10.48-22.54a245.51,245.51,0,0,1-1.77-32.52c0-3.3,1.15-7.67,4.45-7.7s7.74,5.91,9.2,8.57A30.22,30.22,0,0,1,87,150.68Z"
          fill="#fff"
        ></path>
        <path
          d="M117.41,197.4c-1.52,0-3.06-.1-4.58-.2s-2.74-.18-4-.19c-6.6,0-13.29-.27-19.89-.72a18.82,18.82,0,0,1-6.81-1.37c-7.57-3.55-9.76-14.79-10.76-22.93a246.57,246.57,0,0,1-1.77-32.59c0-3.76,1.36-8.15,4.94-8.19h0c3.89,0,8.35,6.54,9.61,8.83a30.14,30.14,0,0,1,3.27,10.57c.17,1.22.3,2.54.38,3.9.16,2.57.16,5.18.17,7.71A72.5,72.5,0,0,0,88.9,176a6.16,6.16,0,0,0,1.38,3.23,5.85,5.85,0,0,0,2.43,1.24,52.27,52.27,0,0,0,10.7,1.73c2.33.21,4.73.42,7.07.84,3.5.63,12,2.84,14.75,9.89a2.79,2.79,0,0,1,0,2.44,2.7,2.7,0,0,1-1.43,1A17.63,17.63,0,0,1,117.41,197.4ZM74.56,132.21h0c-3,0-3.92,4.54-4,7.2a244.52,244.52,0,0,0,1.77,32.46c1,7.91,3.06,18.81,10.19,22.15A18.06,18.06,0,0,0,89,195.29c6.58.45,13.24.7,19.82.72,1.31,0,2.66.1,4.09.19,3.66.24,7.43.49,10.58-.73a1.89,1.89,0,0,0,.94-.61,1.79,1.79,0,0,0-.12-1.54c-2.62-6.58-10.66-8.68-14-9.27-2.3-.41-4.68-.63-7-.84a52.92,52.92,0,0,1-10.91-1.76A6.85,6.85,0,0,1,89.58,180a7,7,0,0,1-1.66-3.74,73.19,73.19,0,0,1-.93-14c0-2.52,0-5.12-.17-7.66-.08-1.34-.2-2.62-.37-3.82h0a29.31,29.31,0,0,0-3.16-10.23C81.86,137.91,77.68,132.21,74.56,132.21Z"
          fill="#231f20"
        ></path>
        <path
          d="M188.22,160.35a12.94,12.94,0,0,1,7.19,4.66,1.33,1.33,0,0,1,.35,1c-.11.55-.78.77-1.34.83a28.29,28.29,0,0,1-5.42-.37c-3.84-.37-7.74.18-11.57-.31-3.32-.42.46-5.56,2.16-6.29C181.93,158.88,185.91,159.73,188.22,160.35Z"
          fill="#fff"
        ></path>
        <path
          d="M193.41,167.41a23.21,23.21,0,0,1-3-.26c-.47-.06-.95-.12-1.43-.16a46,46,0,0,0-5.38-.12,42.91,42.91,0,0,1-6.2-.19,1.76,1.76,0,0,1-1.74-1.51c-.28-2,2.42-5.16,3.76-5.74,2.34-1,6.16-.32,9,.44a13.38,13.38,0,0,1,7.47,4.85,1.82,1.82,0,0,1,.43,1.41c-.14.68-.78,1.12-1.78,1.22A9.42,9.42,0,0,1,193.41,167.41Zm-7.76-1.56c1.13,0,2.27,0,3.4.14l1.46.17a16.85,16.85,0,0,0,3.86.2c.42,0,.85-.19.9-.43s-.13-.44-.27-.62a12.37,12.37,0,0,0-6.91-4.48h0c-3.79-1-6.67-1.19-8.3-.48-1.14.49-3.36,3.34-3.17,4.68,0,.17.08.55.88.66a42.18,42.18,0,0,0,6.05.18Z"
          fill="#231f20"
        ></path>
        <path
          d="M91.86,171.63s-14-33.1-12.4-51.87C80.64,105.82,93,99.87,97.6,99.58c4.25-.27,26.86-3.28,35.1,6.82S140.32,170,140.32,170Z"
          // fill="#68e1fd"
          fill="#00639d"
        ></path>
        <polygon
          points="119.96 108.24 119.68 146.38 103.49 105.68 119.96 108.24"
          fill="#231f20"
        ></polygon>
        <path
          d="M119.68,146.88a.52.52,0,0,1-.47-.32L103,105.87a.5.5,0,0,1,.07-.5.49.49,0,0,1,.47-.18L120,107.75a.49.49,0,0,1,.42.5l-.28,38.13a.51.51,0,0,1-.41.49Zm-15.4-40.57,14.92,37.5.26-35.14Z"
          fill="#231f20"
        ></path>
        <path
          d="M132.7,106.4s6.1,11.72,9.69,17.75,11.41,14.67,11.41,14.67,27.64,9.83,27.82,10.36S179,159,179,159s-29-2.09-33.81-4.36-14.53-13.42-14.53-13.42Z"
          // fill="#68e1fd"
          fill="#00639d"
        ></path>
        <polygon
          points="113.69 112.45 111.65 115.66 113.89 116.99 114.11 132.38 119.68 146.38 119.96 133.42 115.32 116.55 116.7 114.92 113.69 112.45"
          fill="#fff"
        ></polygon>
        <path
          d="M119.68,146.88a.52.52,0,0,1-.47-.32l-5.57-14a.57.57,0,0,1,0-.18l-.22-15.1-2-1.19a.48.48,0,0,1-.23-.31.49.49,0,0,1,.06-.39l2-3.21a.49.49,0,0,1,.34-.22.52.52,0,0,1,.4.11l3,2.47a.5.5,0,0,1,.06.71l-1.21,1.42,4.58,16.62a.66.66,0,0,1,0,.14l-.28,13a.51.51,0,0,1-.41.48Zm-5.07-14.6,4.62,11.62.23-10.42-4.62-16.8a.48.48,0,0,1,.1-.46l1-1.23-2.18-1.79-1.46,2.3,1.79,1.06a.52.52,0,0,1,.25.42Z"
          fill="#231f20"
        ></path>
        <path
          d="M101.84,66.11a7.27,7.27,0,0,1,1.85-.46c2.51-.29,2.55.25,4.49-1.37a18.29,18.29,0,0,1,10.27-3.92c7.21-.47,12.46,3.14,10.82,11A23.32,23.32,0,0,1,125.05,80c-4.08,5.64-9.83,10.76-16.78,11.29-2,.15-5.92,0-7.42-1.69A18.7,18.7,0,0,1,97,71.76a10.43,10.43,0,0,1,1.88-3.6A7.09,7.09,0,0,1,101.84,66.11Z"
          fill="#fff"
        ></path>
        <path
          d="M107,91.87c-1.83,0-5.08-.25-6.56-1.89a19.18,19.18,0,0,1-3.92-18.37,11,11,0,0,1,2-3.77,7.92,7.92,0,0,1,3.12-2.2h0a8.56,8.56,0,0,1,2-.49c.67-.07,1.17-.1,1.57-.11,1.07,0,1.35-.05,2.66-1.15a18.67,18.67,0,0,1,10.56-4c4.26-.28,7.71.84,9.71,3.14,1.79,2.06,2.36,5,1.63,8.52a23,23,0,0,1-4.31,8.81c-3.55,4.92-9.41,10.91-17.14,11.5C108.16,91.84,107.69,91.87,107,91.87Zm-5-25.3a6.75,6.75,0,0,0-2.72,1.91,9.83,9.83,0,0,0-1.78,3.44,18.12,18.12,0,0,0,3.7,17.39c1.19,1.32,4.47,1.71,7,1.52,7.34-.56,13-6.34,16.4-11.09a22,22,0,0,0,4.14-8.43c.66-3.19.18-5.84-1.4-7.65-1.8-2.07-4.95-3.06-8.89-2.8a17.86,17.86,0,0,0-10,3.8A4.23,4.23,0,0,1,105.24,66a14.6,14.6,0,0,0-1.5.11,6.87,6.87,0,0,0-1.72.42Z"
          fill="#231f20"
        ></path>
        <path
          d="M120.58,97.46v10.88s-2.55,6-9.67,4.18-8-6.93-8-6.93l.32-20.15s5.33-2.76,3.91-14.27A35.44,35.44,0,0,0,125.68,74s2.18,12.59,1.41,18.14C126.35,97.48,120.68,97.38,120.58,97.46Z"
          fill="#fff"
        ></path>
        <path
          d="M113.48,113.36a11.23,11.23,0,0,1-2.69-.35c-7.35-1.87-8.33-7.11-8.36-7.33l.31-20.24A.51.51,0,0,1,103,85s5-2.8,3.64-13.77a.5.5,0,0,1,.21-.47.51.51,0,0,1,.52,0,35.09,35.09,0,0,0,18.24,2.75.51.51,0,0,1,.55.41c.09.52,2.18,12.74,1.42,18.29-.45,3.29-2.76,5.32-6.51,5.74v10.43a.48.48,0,0,1,0,.2A8.48,8.48,0,0,1,113.48,113.36Zm-9.74-27.63-.32,19.87c0,.1.92,4.73,7.62,6.44,6.24,1.58,8.7-3.07,9-3.81V97.46a.49.49,0,0,1,.19-.39.76.76,0,0,1,.46-.13c3.44-.31,5.47-2,5.87-4.9.66-4.81-1-15.21-1.34-17.52A36.45,36.45,0,0,1,107.74,72C108.69,81.69,104.82,85,103.74,85.73Zm16.84,22.61h0Z"
          fill="#231f20"
        ></path>
        <path
          d="M107.43,85.14a4.7,4.7,0,0,0-1.55-2.51,8.2,8.2,0,0,0-2.49-1.29,3.55,3.55,0,0,0-1.5-.29,2.9,2.9,0,0,0-1,.36,3.63,3.63,0,0,0-1.75,1.79,4.25,4.25,0,0,0-.11,2,8.49,8.49,0,0,0,.52,2.17,5.65,5.65,0,0,0,7.46,3"
          fill="#fff"
        ></path>
        <path
          d="M104.76,91.41a6.11,6.11,0,0,1-2.32-.45,6.17,6.17,0,0,1-3.37-3.36,9,9,0,0,1-.56-2.3A4.7,4.7,0,0,1,98.66,83a4,4,0,0,1,2-2,3.38,3.38,0,0,1,1.2-.41,3.89,3.89,0,0,1,1.73.31,8.71,8.71,0,0,1,2.64,1.38A5.19,5.19,0,0,1,107.92,85a.5.5,0,0,1-.37.6.51.51,0,0,1-.61-.37A4.15,4.15,0,0,0,105.56,83a7.89,7.89,0,0,0-2.34-1.2,3.08,3.08,0,0,0-1.28-.26,2.34,2.34,0,0,0-.83.3,3.2,3.2,0,0,0-1.52,1.52,3.88,3.88,0,0,0-.09,1.8,8.34,8.34,0,0,0,.49,2,5.15,5.15,0,0,0,6.8,2.77.51.51,0,0,1,.66.26.5.5,0,0,1-.27.66A6.17,6.17,0,0,1,104.76,91.41Z"
          fill="#231f20"
        ></path>
        <path
          d="M120.17,81.21s3.87,5.5,3,6.49-3.37.41-3.37.41"
          fill="#fff"
        ></path>
        <path
          d="M121.33,88.78a8.15,8.15,0,0,1-1.64-.18.5.5,0,0,1,.23-1c.86.2,2.39.31,2.88-.25.22-.49-1.19-3.25-3-5.87a.5.5,0,0,1,.82-.57c1.53,2.17,4,6,3,7.1A2.9,2.9,0,0,1,121.33,88.78Z"
          fill="#231f20"
        ></path>
        <ellipse
          cx="117.09"
          cy="80.01"
          rx="1.3"
          ry="0.92"
          transform="translate(30.03 191.95) rotate(-86.47)"
          fill="#231f20"
        ></ellipse>
        <ellipse
          cx="125.05"
          cy="80.04"
          rx="0.92"
          ry="1.3"
          transform="translate(-5.31 8.97) rotate(-4.02)"
          fill="#231f20"
        ></ellipse>
        <path
          d="M120.35,92.08c-2.14,0-4.95-.33-6.81-1.83a.5.5,0,0,1,.63-.77c2.94,2.36,9,1.41,9,1.4a.5.5,0,0,1,.16,1A19.92,19.92,0,0,1,120.35,92.08Z"
          fill="#231f20"
        ></path>
        <path
          d="M127.31,86.93a6.92,6.92,0,1,1,6.82-5.73A6.93,6.93,0,0,1,127.31,86.93Zm0-12.84a5.93,5.93,0,1,0,1,.09A5.86,5.86,0,0,0,127.31,74.09Z"
          fill="#231f20"
        ></path>
        <path
          d="M113.87,86.4a7.14,7.14,0,0,1-1.21-.1,6.92,6.92,0,0,1-2.78-12.48,6.92,6.92,0,1,1,4,12.58Zm0-12.84a5.92,5.92,0,0,0-1,11.75,5.92,5.92,0,0,0,2-11.66A6.09,6.09,0,0,0,113.84,73.56Z"
          fill="#231f20"
        ></path>
        <path
          d="M120.58,97.46s-6.77,1.57-10.76-.71c0,0,4.42,9.12,10.76,8.17Z"
          fill="#231f20"
        ></path>
        <path
          d="M119.64,105.49c-6.07,0-10.09-8.16-10.27-8.53a.51.51,0,0,1,.11-.58.5.5,0,0,1,.59-.07c3.77,2.16,10.34.68,10.4.66a.5.5,0,0,1,.61.49v7.46a.49.49,0,0,1-.42.49A7.23,7.23,0,0,1,119.64,105.49ZM111,97.81c1.42,2.33,4.82,7,9.09,6.66v-6.4C118.52,98.36,114.33,99,111,97.81Z"
          fill="#231f20"
        ></path>
        <path
          d="M86.38,105.33C78,110.64,67,161.74,74.13,167.25c11.17,8.61,42.57-8.41,42.57-8.41l-3.17-15s-25,.69-25.34-4.44,14.53-28.88,12.58-32.42S86.38,105.33,86.38,105.33Z"
          // fill="#68e1fd"
          fill="#00639d"
        ></path>
        <path
          d="M97.55,167.45a.49.49,0,0,1-.48-.36.51.51,0,0,1,.35-.62l13.73-3.86V150.68l-21.89-2.24a.49.49,0,0,1-.36-.2.52.52,0,0,1-.08-.4l8.3-37.31a.5.5,0,1,1,1,.22L89.92,147.5l21.78,2.23a.5.5,0,0,1,.45.5V163a.5.5,0,0,1-.36.48l-14.1,4A.34.34,0,0,1,97.55,167.45Z"
          fill="#231f20"
        ></path>
        <path
          d="M151,160.69c-.23.51-1,.36-1.49.13l-7.14-3.27a22.35,22.35,0,0,1,4.55,3c.27.23.56.54.48.89-.13.6-1,.49-1.57.22A51.66,51.66,0,0,1,138,157l5.52,4.23a.85.85,0,0,1-.86.85,2.54,2.54,0,0,1-1.28-.45L135.27,158l3.84,2.69c.6.42,1.29,1.09,1,1.77s-1.53.56-2.32.2a46.5,46.5,0,0,0-9.88-3,16,16,0,0,0-3.65-.75,33.29,33.29,0,0,0-5.61,1.23q-3.49.78-7,1.61v-9.82a55.42,55.42,0,0,0,12-.62,38.72,38.72,0,0,1,9.27-1.07,35.51,35.51,0,0,1,12,2.74,1.13,1.13,0,0,1,.4.27c.36.52-.5,1-1.13,1a16.45,16.45,0,0,1-4.89-.61,46.55,46.55,0,0,1,11.05,5.88C150.73,159.82,151.16,160.27,151,160.69Z"
          fill="#fff"
        ></path>
        <path
          d="M139.13,163.49a3.87,3.87,0,0,1-1.54-.38,45.84,45.84,0,0,0-9.78-3c-.42-.09-.83-.21-1.23-.32a7.11,7.11,0,0,0-2.32-.42,17.07,17.07,0,0,0-3.76.76c-.6.16-1.19.33-1.76.45l-6.92,1.59a.49.49,0,0,1-.42-.08.45.45,0,0,1-.2-.34v-9.82a.45.45,0,0,1,.14-.33.44.44,0,0,1,.33-.13,54.24,54.24,0,0,0,11.9-.61l1.08-.2a33.6,33.6,0,0,1,8.3-.87,35.62,35.62,0,0,1,12.14,2.77,1.35,1.35,0,0,1,.59.43.84.84,0,0,1,.07.91,1.91,1.91,0,0,1-1.56.84c-.4,0-.78,0-1.17,0a47.65,47.65,0,0,1,7.59,4.44c.74.53,1,1.15.78,1.71a1.1,1.1,0,0,1-1.06.61,2.77,2.77,0,0,1-1-.25l-2.06-1a1.4,1.4,0,0,1,.58,1.3,1,1,0,0,1-.52.67,2.11,2.11,0,0,1-1.69-.14c-.53-.27-1.06-.54-1.58-.82a1.12,1.12,0,0,1-.27.7,1.48,1.48,0,0,1-1,.5,3,3,0,0,1-1.52-.51l-.69-.41a1.41,1.41,0,0,1,0,1.11,1.3,1.3,0,0,1-.73.69A1.87,1.87,0,0,1,139.13,163.49Zm-14.75-5a9,9,0,0,1,2.45.45c.38.11.77.22,1.17.3a46.46,46.46,0,0,1,10,3.07,2.25,2.25,0,0,0,1.49.25.35.35,0,0,0,.22-.21c.2-.45-.59-1-.83-1.22L137.23,160,135,158.42a.47.47,0,0,1-.12-.63.46.46,0,0,1,.61-.15l6.15,3.55a1.75,1.75,0,0,0,1,.39.53.53,0,0,0,.36-.17v0l-5.3-4.07a.46.46,0,0,1-.08-.64.45.45,0,0,1,.63-.09A51.89,51.89,0,0,0,146,161.3a1.3,1.3,0,0,0,.89.14c0-.08,0-.21-.29-.49a22.36,22.36,0,0,0-4.47-3,.46.46,0,0,1-.2-.61.45.45,0,0,1,.6-.21l3.07,1.4,4.07,1.86a1.73,1.73,0,0,0,.67.18c.18,0,.2-.05.21-.08h0c0-.08-.1-.32-.47-.58a46.62,46.62,0,0,0-11-5.83.45.45,0,0,1,.28-.86,16.49,16.49,0,0,0,4.76.6,1.06,1.06,0,0,0,.77-.34l-.22-.09a34.41,34.41,0,0,0-11.81-2.7,33.17,33.17,0,0,0-8.09.86l-1.08.2a54.33,54.33,0,0,1-11.63.64v8.77c2.18-.5,4.3-1,6.43-1.48.55-.12,1.13-.28,1.71-.44a17.32,17.32,0,0,1,4-.79Z"
          fill="#231f20"
        ></path>
        <path
          d="M136.32,147.74a.45.45,0,0,1-.45-.44c-.63-13.74-2.21-34.59-2.23-34.8a.45.45,0,0,1,.42-.49.45.45,0,0,1,.49.42c0,.21,1.6,21.08,2.23,34.83a.46.46,0,0,1-.44.48Z"
          fill="#231f20"
        ></path>
        <rect
          x="146.51"
          y="34.19"
          width="126.71"
          height="90.58"
          fill="#fff"
        ></rect>
        <path
          d="M273.22,125.27H146.51a.5.5,0,0,1-.5-.5V34.19a.5.5,0,0,1,.5-.5H273.22a.5.5,0,0,1,.5.5v90.58A.5.5,0,0,1,273.22,125.27Zm-126.21-1H272.72V34.69H147Z"
          fill="#231f20"
        ></path>
        <path
          d="M243.52,95.46h0a.5.5,0,0,1-.44-.33L226.37,48.67,208,84.5a.52.52,0,0,1-.4.27.5.5,0,0,1-.44-.2L188.93,60.8,177.47,79.17a.5.5,0,0,1-.68.16l-9.66-5.81a.49.49,0,0,1-.17-.69.5.5,0,0,1,.68-.17l9.24,5.56,11.58-18.56a.52.52,0,0,1,.4-.24.46.46,0,0,1,.42.2l18.14,23.72L226,47.19a.5.5,0,0,1,.92.06l16.66,46.4,8.18-17.3a.5.5,0,0,1,.9.43L244,95.17A.49.49,0,0,1,243.52,95.46Z"
          fill="#d1d3d4"
        ></path>
        <circle cx="177.04" cy="78.86" r="2.29" fill="#fff"></circle>
        <path
          d="M177,81.65a2.79,2.79,0,1,1,2.79-2.79A2.8,2.8,0,0,1,177,81.65Zm0-4.58a1.79,1.79,0,1,0,1.79,1.79A1.79,1.79,0,0,0,177,77.07Z"
          fill="#d1d3d4"
        ></path>
        <circle cx="188.89" cy="59.92" r="2.29" fill="#fff"></circle>
        <path
          d="M188.89,62.71a2.79,2.79,0,1,1,2.79-2.79A2.79,2.79,0,0,1,188.89,62.71Zm0-4.58a1.79,1.79,0,1,0,1.79,1.79A1.79,1.79,0,0,0,188.89,58.13Z"
          fill="#d1d3d4"
        ></path>
        <circle cx="226.45" cy="47.42" r="2.29" fill="#fff"></circle>
        <path
          d="M226.45,50.2a2.79,2.79,0,1,1,2.79-2.78A2.79,2.79,0,0,1,226.45,50.2Zm0-4.57a1.79,1.79,0,1,0,1.79,1.79A1.79,1.79,0,0,0,226.45,45.63Z"
          fill="#d1d3d4"
        ></path>
        <circle cx="243.52" cy="94.96" r="2.29" fill="#fff"></circle>
        <path
          d="M243.52,97.75A2.79,2.79,0,1,1,246.31,95,2.79,2.79,0,0,1,243.52,97.75Zm0-4.58A1.79,1.79,0,1,0,245.31,95,1.79,1.79,0,0,0,243.52,93.17Z"
          fill="#d1d3d4"
        ></path>
        <circle cx="207.51" cy="84.27" r="2.29" fill="#fff"></circle>
        <path
          d="M207.51,87.06a2.79,2.79,0,1,1,2.79-2.79A2.8,2.8,0,0,1,207.51,87.06Zm0-4.58a1.79,1.79,0,1,0,1.79,1.79A1.79,1.79,0,0,0,207.51,82.48Z"
          fill="#d1d3d4"
        ></path>
        <path
          d="M180.69,49.36H170.08a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5h10.61a.5.5,0,0,1,.5.5A.5.5,0,0,1,180.69,49.36Z"
          fill="#d1d3d4"
        ></path>
        <path
          d="M180.69,52.11H170.08a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5h10.61a.5.5,0,0,1,.5.5A.5.5,0,0,1,180.69,52.11Z"
          fill="#d1d3d4"
        ></path>
        <path
          d="M180.69,54.85H170.08a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5h10.61a.5.5,0,0,1,.5.5A.5.5,0,0,1,180.69,54.85Z"
          fill="#d1d3d4"
        ></path>
        <path
          d="M177,57.6h-6.89a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5H177a.5.5,0,0,1,.5.5A.51.51,0,0,1,177,57.6Z"
          fill="#d1d3d4"
        ></path>
        <path
          d="M252.34,60H241.73a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5h10.61a.5.5,0,0,1,.5.5A.5.5,0,0,1,252.34,60Z"
          fill="#d1d3d4"
        ></path>
        <path
          d="M252.34,62.71H241.73a.5.5,0,0,1,0-1h10.61a.5.5,0,0,1,0,1Z"
          fill="#d1d3d4"
        ></path>
        <path
          d="M248.77,65.45h-7a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5h7a.5.5,0,0,1,.5.5A.5.5,0,0,1,248.77,65.45Z"
          fill="#d1d3d4"
        ></path>
        <path
          d="M255.28,103.85H164.45a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h90.83a.5.5,0,0,1,.5.5A.5.5,0,0,1,255.28,103.85Z"
          fill="#d1d3d4"
        ></path>
        <path
          d="M255.28,109.09H164.45a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h90.83a.5.5,0,0,1,.5.5A.5.5,0,0,1,255.28,109.09Z"
          fill="#d1d3d4"
        ></path>
        <path
          d="M240.3,114.34H164.45a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H240.3a.5.5,0,0,1,.5.5A.5.5,0,0,1,240.3,114.34Z"
          fill="#d1d3d4"
        ></path>
        <line x1="293.55" y1="193.2" x2="281.56" y2="265.81" fill="#fff"></line>
        <path
          d="M281.56,266.31h-.08a.5.5,0,0,1-.41-.57l12-72.61a.49.49,0,0,1,.57-.41.48.48,0,0,1,.41.57l-12,72.61A.51.51,0,0,1,281.56,266.31Z"
          fill="#231f20"
        ></path>
        <line x1="306.76" y1="193.2" x2="318.99" y2="265.81" fill="#fff"></line>
        <path
          d="M319,266.31a.5.5,0,0,1-.49-.42l-12.23-72.61a.5.5,0,0,1,.41-.57.51.51,0,0,1,.58.41l12.23,72.6a.51.51,0,0,1-.41.58Z"
          fill="#231f20"
        ></path>
        <path
          d="M264.89,237.33A15.2,15.2,0,0,1,267,248.67c-1.37,6.64-18,17.14-18,17.14h21.36a16.24,16.24,0,0,0,10.3-8.8c3.32-7.24.91-9.19.91-9.19l3.78,4.69,6.45-5.67-13.35-18.63Z"
          fill="#231f20"
        ></path>
        <path
          d="M249,266.25a.43.43,0,0,1-.42-.32.45.45,0,0,1,.18-.5c.17-.1,16.47-10.47,17.79-16.85A14.2,14.2,0,0,0,264.75,238a3.52,3.52,0,0,1-.28-.55.46.46,0,0,1,.17-.51l13.55-9.12a.45.45,0,0,1,.61.11l13.35,18.63a.45.45,0,0,1-.07.59l-6.45,5.67a.43.43,0,0,1-.32.11.45.45,0,0,1-.31-.16l-2.17-2.7c.09,1.51-.25,3.78-1.78,7.1a16.52,16.52,0,0,1-10.61,9.05Zm1.48-.88h19.88a16.07,16.07,0,0,0,9.9-8.55c2.49-5.41,1.66-7.71,1.22-8.41l-.25-.31a.44.44,0,0,1,.62-.62,1.44,1.44,0,0,1,.35.42l3.21,4,5.8-5.11-12.87-18-12.87,8.67.06.09a15.29,15.29,0,0,1,1.88,11.18C266.22,254.49,254.41,262.75,250.47,265.37Zm14.42-28h0Z"
          fill="#231f20"
        ></path>
        <path
          d="M250.81,245.28l-8.07,20.52h-7l1.17-4.89s-6.25,5.09-7.82,4.89-29.12.39-29.12-1,18.24-9.18,25.44-11.72,9.74-10.17,9.74-10.17Z"
          fill="#231f20"
        ></path>
        <path
          d="M229.12,266.25H229c-.4-.05-2.9-.05-6.06,0-14.43,0-22.41-.11-23.27-1a.55.55,0,0,1-.18-.41c0-2,21.39-10.61,25.74-12.14,6.9-2.44,9.44-9.81,9.46-9.89a.44.44,0,0,1,.49-.29l15.7,2.34a.42.42,0,0,1,.31.22.42.42,0,0,1,0,.38L243.15,266a.44.44,0,0,1-.41.28h-7a.44.44,0,0,1-.43-.54l.88-3.64C234.24,263.54,230.57,266.25,229.12,266.25Zm-3.24-.94c1.87,0,2.92,0,3.23.05.93.15,4.95-2.73,7.48-4.79a.45.45,0,0,1,.52,0,.45.45,0,0,1,.19.48l-1,4.34h6.18l7.76-19.73-14.8-2.21c-.6,1.57-3.37,7.79-9.88,10.09-7.43,2.62-23,9.39-25,11.19,2.29.67,15.76.64,22.4.62Z"
          fill="#231f20"
        ></path>
        <path
          d="M281.56,165.28s-61,5.34-61.5,20.58,45.35,55.25,45.35,55.25l16.15-13.81s-39.83-33.91-34-39.77,20.44-.84,20.44-.84Z"
          fill="#231f20"
        ></path>
        <path
          d="M265.41,241.55a.44.44,0,0,1-.29-.11c-.12-.1-11.6-10.15-22.88-21.93-15.21-15.89-22.82-27.22-22.62-33.66.49-15.46,59.4-20.79,61.91-21a.41.41,0,0,1,.41.21.43.43,0,0,1,0,.46l-13.53,21.41a.45.45,0,0,1-.52.18c-.14,0-14.38-4.86-20,.74a1.78,1.78,0,0,0-.46,1.39c.35,8.65,34.06,37.44,34.4,37.73a.5.5,0,0,1,.16.34.42.42,0,0,1-.16.33l-16.16,13.81A.43.43,0,0,1,265.41,241.55Zm15.3-75.75c-7.51.74-59.78,6.38-60.21,20.08-.44,14.24,41,51.23,44.91,54.65l15.48-13.23c-4-3.43-34-29.42-34.32-38a2.67,2.67,0,0,1,.71-2.05c5.57-5.56,18.29-1.8,20.57-1.06Z"
          fill="#231f20"
        ></path>
        <path
          d="M318.27,171.94s-3.66,13.53-9.69,15.1-24.39,5.86-31.24,9-22.49,50.81-22.49,50.81H228.07s24.31-53,30.56-62,18.71-17.57,18.71-17.57Z"
          fill="#231f20"
        ></path>
        <path
          d="M254.85,247.28H228.07a.43.43,0,0,1-.37-.2.45.45,0,0,1,0-.42c1-2.17,24.39-53.17,30.6-62.09S277,167,277.09,166.89a.4.4,0,0,1,.3-.07l40.93,4.69a.43.43,0,0,1,.32.19.45.45,0,0,1,.06.36c-.16.56-3.8,13.79-10,15.4l-3.94,1c-8.06,2-21.56,5.38-27.23,8-5.28,2.41-16.86,34.12-22.25,50.55A.44.44,0,0,1,254.85,247.28Zm-26.09-.88h25.77c1.47-4.44,15.89-47.7,22.62-50.78,5.75-2.62,19.29-6,27.38-8l3.94-1c5.08-1.32,8.51-11.86,9.23-14.29l-40.25-4.61c-1.36,1-12.67,9-18.46,17.36S231.52,240.41,228.76,246.4Z"
          fill="#231f20"
        ></path>
        <path
          d="M315.79,150.68c-.18,1.29-.3,2.58-.38,3.86-.43,7.2.31,14.51-1.09,21.58a6.56,6.56,0,0,1-1.52,3.48,6.38,6.38,0,0,1-2.63,1.37c-5.74,1.78-11.91,1.52-17.83,2.58s-12.15,4-14.37,9.58a2.24,2.24,0,0,0-.08,2,2.35,2.35,0,0,0,1.18.81c4.48,1.73,10.12.59,14.85.57q9.94,0,19.86-.72a18.65,18.65,0,0,0,6.63-1.32c7.59-3.56,9.58-15.24,10.47-22.54a245.52,245.52,0,0,0,1.78-32.52c0-3.3-1.15-7.67-4.46-7.7s-7.73,5.91-9.2,8.57A30.2,30.2,0,0,0,315.79,150.68Z"
          fill="#fff"
        ></path>
        <path
          d="M285.33,197.4a17.65,17.65,0,0,1-6.44-1,2.7,2.7,0,0,1-1.43-1,2.79,2.79,0,0,1,0-2.44c2.81-7,11.25-9.26,14.75-9.89,2.34-.42,4.74-.63,7.07-.84a52.27,52.27,0,0,0,10.7-1.73,5.79,5.79,0,0,0,2.43-1.24,6.19,6.19,0,0,0,1.38-3.23,72.5,72.5,0,0,0,.91-13.8c0-2.53,0-5.14.17-7.71.08-1.35.21-2.67.38-3.9h0A30.35,30.35,0,0,1,318.56,140c1.26-2.29,5.73-8.83,9.62-8.83h0c3.58,0,4.9,4.43,5,8.19A246.58,246.58,0,0,1,331.38,172c-1,8.14-3.18,19.38-10.76,22.93a18.78,18.78,0,0,1-6.81,1.37c-6.6.45-13.29.7-19.89.72-1.28,0-2.61.1-4,.19S286.84,197.4,285.33,197.4Zm30.95-46.65c-.17,1.21-.29,2.49-.37,3.82-.15,2.54-.16,5.14-.17,7.65a73.24,73.24,0,0,1-.93,14,7,7,0,0,1-1.66,3.74,6.85,6.85,0,0,1-2.83,1.49,52.92,52.92,0,0,1-10.91,1.76c-2.3.21-4.69.43-7,.84-3.33.59-11.37,2.69-14,9.27a1.79,1.79,0,0,0-.12,1.54,1.89,1.89,0,0,0,.94.61c3.15,1.22,6.93,1,10.58.73,1.43-.09,2.78-.18,4.09-.19,6.58,0,13.25-.27,19.82-.72a18,18,0,0,0,6.45-1.27c7.13-3.35,9.23-14.24,10.19-22.15a244.53,244.53,0,0,0,1.78-32.46c0-2.66-.91-7.17-4-7.2h0c-3.13,0-7.31,5.7-8.74,8.31a29.38,29.38,0,0,0-3.16,10.23Z"
          fill="#231f20"
        ></path>
        <path
          d="M296.88,76.13s-7.53,21.53-10.88,26l19.53,7.32,3.67-34.75Z"
          fill="#fff"
        ></path>
        <path
          d="M305.53,110a.39.39,0,0,1-.18,0l-19.53-7.31a.51.51,0,0,1-.23-.77c3.28-4.38,10.75-25.68,10.82-25.9a.51.51,0,0,1,.42-.33l12.32-1.39a.46.46,0,0,1,.4.14.47.47,0,0,1,.15.41L306,109.53a.48.48,0,0,1-.23.37A.5.5,0,0,1,305.53,110Zm-18.76-8.06,18.33,6.86,3.54-33.48-11.39,1.29C296.33,79.2,290.08,96.79,286.77,101.92Z"
          fill="#231f20"
        ></path>
        <path
          d="M235.89,118.41s-4.38-4.74-4.65-6.44-.63-7.63-2.07-6.59-1.1,5.28-1.1,5.28-6-7.16-7.73-5.63-2.49,3-2.61,6.17,14.94,16.22,14.94,16.22"
          fill="#fff"
        ></path>
        <path
          d="M232.67,127.92a.52.52,0,0,1-.33-.12c-1.56-1.36-15.24-13.36-15.11-16.62s.85-4.83,2.78-6.52a1.51,1.51,0,0,1,1.09-.34c1.85.15,4.89,3.25,6.43,5,0-1.43.26-3.53,1.35-4.31a1,1,0,0,1,1-.14c.92.41,1.3,2.28,1.71,5.83.06.5.11.93.16,1.23.18,1.13,2.87,4.39,4.53,6.18a.5.5,0,0,1,0,.71.5.5,0,0,1-.7,0c-.46-.5-4.5-4.9-4.79-6.7-.05-.31-.1-.76-.16-1.28-.14-1.17-.55-4.72-1.12-5-.88.67-1,3.34-.89,4.87a.49.49,0,0,1-.31.5.48.48,0,0,1-.57-.14c-1.28-1.53-5-5.53-6.67-5.66a.4.4,0,0,0-.35.09c-1.72,1.5-2.33,3-2.44,5.81-.08,2.1,9,10.82,14.77,15.82a.51.51,0,0,1,.05.71A.52.52,0,0,1,232.67,127.92Z"
          fill="#231f20"
        ></path>
        <path
          d="M227.66,117.19a.5.5,0,0,1-.46-.29,6.86,6.86,0,0,1,.46-6.53.5.5,0,1,1,.82.58,5.84,5.84,0,0,0-.37,5.54.5.5,0,0,1-.25.66A.51.51,0,0,1,227.66,117.19Z"
          fill="#231f20"
        ></path>
        <path
          d="M324.49,165.28c-.64,2-2.65,3.3-5.5,4.12-.44,1.63-.72,2.54-.72,2.54l-47.39-5.43,9.19-31.46S273.86,144,265.86,148s-37.15-22.91-37.15-22.91l7.18-10A115.07,115.07,0,0,0,264,129.6c13.9-12.76,22.05-21.38,26-21.53,10.68-.4,19.6-.95,28.24,4.71,4.58,3,5.15,1.24,6.22,7.92h0a58,58,0,0,1,.68,8.4v.13C325.93,140.07,326.75,158.17,324.49,165.28Z"
          // fill="#68e1fd"
          fill="#00639d"
        ></path>
        <path
          d="M313.2,111.93l5.07.84-8.93,11.3c-6.72,8.47-14.81,18.65-15.27,19s-1.94-13.38-3-23.75c-.61-6.23-1.07-11.23-1.07-11.23l9,1.5,1.62-7.07s-6.52.07-6.61-4.85a43.26,43.26,0,0,1,.67-7.69c.71-4.26,2.72-8.7,4-12.88a11.24,11.24,0,0,1,3.61.12s2.62.39,2.08-.45c.39.09,6.72,1.9,13.88,3.37-1.33,6.36-2.37,13.58-2.48,14.33Z"
          fill="#fff"
        ></path>
        <path
          d="M294,143.56a.46.46,0,0,1-.2-.05c-.45-.19-.88-.38-3.24-24.16-.61-6.24-1.07-11.24-1.07-11.24a.53.53,0,0,1,.16-.41.51.51,0,0,1,.42-.13l8.56,1.43,1.39-6c-2.52-.15-6.42-1.38-6.49-5.32a43.77,43.77,0,0,1,.68-7.78,50.06,50.06,0,0,1,2.46-8.43c.52-1.49,1.07-3,1.51-4.51a.48.48,0,0,1,.4-.35,11.64,11.64,0,0,1,3.77.12h0a6.47,6.47,0,0,0,1.5.06.5.5,0,0,1,.1-.31.48.48,0,0,1,.52-.2l.42.12c4.76,1.3,9.29,2.4,13.44,3.25a.5.5,0,0,1,.39.59c-1.1,5.25-2,11.12-2.46,14.15l-2.53,17.14,4.58.77a.52.52,0,0,1,.39.31.51.51,0,0,1-.08.49l-8.92,11.3c-12.34,15.55-15,18.83-15.37,19.08A.61.61,0,0,1,294,143.56Zm-3.45-34.89c.14,1.54.53,5.68,1,10.58,1.57,15.88,2.31,21.21,2.65,22.87,2.22-2.63,11.13-13.85,14.7-18.36l8.4-10.63-4.23-.71a.5.5,0,0,1-.41-.57l2.6-17.63c.44-3,1.31-8.57,2.37-13.71-4-.84-8.32-1.89-12.86-3.13l-.05.06c-.49.52-2,.35-2.55.25h0a11,11,0,0,0-3.14-.17c-.42,1.4-.94,2.85-1.43,4.25a50.52,50.52,0,0,0-2.42,8.27,43.51,43.51,0,0,0-.66,7.6c.08,4.35,5.85,4.35,6.11,4.35a.66.66,0,0,1,.39.19.49.49,0,0,1,.1.42l-1.63,7.07a.5.5,0,0,1-.57.38Z"
          fill="#231f20"
        ></path>
        <path
          d="M303.32,94.1c-.75,0-2.35-.12-2.94-1.08s1.58-3.75,3.59-5.8a.5.5,0,1,1,.71.7c-1.92,2-3.53,4.11-3.44,4.61.32.51,1.75.6,2.39.56a.5.5,0,0,1,.06,1Z"
          fill="#231f20"
        ></path>
        <ellipse
          cx="300.71"
          cy="85.78"
          rx="1.06"
          ry="0.56"
          transform="translate(149.75 359.63) rotate(-77.06)"
          fill="#231f20"
        ></ellipse>
        <ellipse
          cx="308.58"
          cy="88"
          rx="1.06"
          ry="0.56"
          transform="translate(153.69 369.02) rotate(-77.06)"
          fill="#231f20"
        ></ellipse>
        <path
          d="M302.49,83.32l1-2,.74,2.37,6.2,1.84L312.19,83,312,86.43l3.26.34s-6.14,15.33-5.91,24.85c0,0,18.44,5.09,23.22,1.79,0,0-5.24-18.86-5-26.41s-.21-14-9.18-17.44S303,67.18,298.26,71.29a13.87,13.87,0,0,0-4.53,10S300.62,83.15,302.49,83.32Z"
          fill="#fff"
        ></path>
        <path
          d="M327.05,115c-7.15,0-17.25-2.75-17.82-2.91a.51.51,0,0,1-.37-.47c-.21-8.54,4.55-21.44,5.71-24.43L312,86.93a.5.5,0,0,1-.45-.52l.09-1.67-.71,1.06a.5.5,0,0,1-.56.21l-6.2-1.84a.48.48,0,0,1-.33-.33l-.38-1.21-.48.92a.53.53,0,0,1-.49.27c-1.9-.18-8.57-1.95-8.85-2a.49.49,0,0,1-.37-.46,14.24,14.24,0,0,1,4.7-10.42c5.17-4.51,12.12-5.12,20.65-1.81,9.19,3.56,9.74,10.22,9.5,17.91-.24,7.4,4.93,26.08,5,26.27a.5.5,0,0,1-.19.55C331.61,114.69,329.51,115,327.05,115Zm-17.19-3.78c2.53.67,17.57,4.48,22.14,2-.75-2.73-5.15-19.15-4.92-26.21.24-7.56-.26-13.62-8.86-17-8.27-3.21-14.7-2.67-19.63,1.63a13.45,13.45,0,0,0-4.35,9.27c1.37.36,6.1,1.58,8,1.85l.88-1.69a.5.5,0,0,1,.92.08l.66,2.11,5.59,1.66,1.51-2.26a.5.5,0,0,1,.92.3l-.16,3,2.79.29a.49.49,0,0,1,.38.24.52.52,0,0,1,0,.44C315.68,87.1,309.79,101.9,309.86,111.23Z"
          fill="#231f20"
        ></path>
        <path
          d="M302.15,97.52c-2.91,0-5-2.51-5.12-2.61a.5.5,0,1,1,.76-.65s1.92,2.26,4.36,2.26h.06q2.22,0,4.16-2.51a.5.5,0,0,1,.79.61c-1.49,1.9-3.15,2.87-4.94,2.9Z"
          fill="#231f20"
        ></path>
        <path
          d="M300.69,102.5s5.15-.13,7.34-1.47c0,0-3.16,6.73-9,8.84Z"
          fill="#231f20"
        ></path>
        <path
          d="M299,110.37a.46.46,0,0,1-.33-.13.5.5,0,0,1-.15-.48l1.69-7.37a.51.51,0,0,1,.47-.39c.05,0,5.05-.14,7.1-1.4a.51.51,0,0,1,.6.06.49.49,0,0,1,.11.58c-.13.28-3.32,6.94-9.32,9.1A.47.47,0,0,1,299,110.37Zm2.1-7.39-1.4,6.07c3.61-1.67,6.07-5.11,7.2-7A24.12,24.12,0,0,1,301.09,103Z"
          fill="#231f20"
        ></path>
        <path
          d="M308,170.78a188.3,188.3,0,0,1-25.47-2.15.49.49,0,0,1-.42-.43l-1-9.38a.5.5,0,0,1,.36-.54c1-.27,24.1-6.51,27.81-6.27a.57.57,0,0,0,.43-.2c2.11-2,2.11-14.89,1.93-19.67a.5.5,0,1,1,1,0c.07,1.8.57,17.71-2.24,20.42a1.51,1.51,0,0,1-1.19.47c-2.76-.2-18.68,3.86-27.06,6.13l.95,8.58c3.08.47,24.32,3.55,35.83,1.2a.5.5,0,0,1,.59.39.49.49,0,0,1-.39.59A57.47,57.47,0,0,1,308,170.78Z"
          fill="#231f20"
        ></path>
        <path
          d="M309.34,124.07c-6.72,8.47-14.81,18.65-15.27,19s-1.94-13.38-3-23.75Z"
          fill="#231f20"
        ></path>
        <path
          d="M294,143.55a.45.45,0,0,1-.2,0c-.45-.2-.88-.38-3.23-24.16a.48.48,0,0,1,.17-.43.51.51,0,0,1,.45-.1l18.24,4.77a.52.52,0,0,1,.35.33.48.48,0,0,1-.09.46c-12.33,15.55-15,18.82-15.36,19.08A.6.6,0,0,1,294,143.55ZM291.67,120c1.52,15.34,2.24,20.53,2.58,22.16,2.12-2.51,10.4-12.93,14.23-17.76Z"
          fill="#231f20"
        ></path>
        <path
          d="M281.15,158.91s-8.82-.74-12.78,1.11-8.08,4.16-7.17,5.65,21.8,2.5,21.8,2.5Z"
          fill="#fff"
        ></path>
        <path
          d="M283,168.67h0c-6.36-.31-21.25-1.19-22.2-2.74a1.39,1.39,0,0,1-.14-1.15c.26-.85,1.32-2.33,7.51-5.21,4-1.88,12.68-1.19,13-1.15a.48.48,0,0,1,.45.4l1.85,9.25a.49.49,0,0,1-.49.6Zm-5.9-9.4c-2.8,0-6.41.22-8.52,1.2-5.66,2.64-6.8,4-7,4.6a.4.4,0,0,0,0,.34c.83.78,11.09,1.74,20.75,2.23l-1.64-8.25C280.11,159.34,278.74,159.27,277.1,159.27Z"
          fill="#231f20"
        ></path>
        <rect
          x="54.33"
          y="167.25"
          width="291.34"
          height="8.83"
          fill="#fff"
        ></rect>
        <path
          d="M345.67,176.58H54.33a.5.5,0,0,1-.5-.5v-8.83a.5.5,0,0,1,.5-.5H345.67a.5.5,0,0,1,.5.5v8.83A.5.5,0,0,1,345.67,176.58Zm-290.84-1H345.17v-7.83H54.83Z"
          fill="#231f20"
        ></path>
        <polygon
          points="138.04 166.88 148.01 133.59 189.84 133.59 180.97 166.6 138.04 166.88"
          fill="#fff"
        ></polygon>
        <path
          d="M138,167.38a.51.51,0,0,1-.4-.2.49.49,0,0,1-.07-.44l10-33.29a.5.5,0,0,1,.48-.36h41.84a.5.5,0,0,1,.39.2.48.48,0,0,1,.09.43l-8.88,33a.49.49,0,0,1-.48.37l-42.92.28Zm10.34-33.29-9.66,32.29,41.87-.27,8.6-32Z"
          fill="#231f20"
        ></path>
        <path
          d="M241.52,218.62l-.13,0A.51.51,0,0,1,241,218c1.72-6.24,9.73-20,10.06-20.56a.51.51,0,0,1,.69-.18.5.5,0,0,1,.17.68c-.08.14-8.27,14.21-10,20.32A.5.5,0,0,1,241.52,218.62Z"
          fill="#fff"
        ></path>
        <ellipse
          cx="165.35"
          cy="148.76"
          rx="5.38"
          ry="3.54"
          transform="translate(-56.77 137.31) rotate(-39.03)"
          // fill="#68e1fd"
          fill="#ff8c00"
        ></ellipse>
        <path
          d="M142.14,223.2a.53.53,0,0,1-.24-.06.5.5,0,0,1-.2-.68c8.4-15.3,20.54-39.13,19.6-42a.49.49,0,0,1,.32-.63.5.5,0,0,1,.63.32c1.37,4.26-17.51,38.9-19.67,42.83A.49.49,0,0,1,142.14,223.2Z"
          fill="#fff"
        ></path>
        <path
          d="M104.49,248.67s-6.65,12.78-6.65,17.14h49.39s1.68-4.37-5.09-5.81-19-4.3-20.19-6.51,1.17-4.82,1.17-4.82Z"
          fill="#231f20"
        ></path>
        <path
          d="M147.23,266.25H97.84a.44.44,0,0,1-.44-.44c0-4.42,6.42-16.82,6.7-17.35a.43.43,0,0,1,.39-.23h18.63a.44.44,0,0,1,.4.26.45.45,0,0,1-.07.47s-2.12,2.41-1.11,4.32c.77,1.47,8.21,3.82,19.89,6.29,2.59.55,4.32,1.59,5.13,3.08a4.23,4.23,0,0,1,.28,3.31A.44.44,0,0,1,147.23,266.25Zm-48.93-.88h48.59a3.35,3.35,0,0,0-.31-2.31c-.69-1.25-2.21-2.14-4.53-2.63-8.69-1.84-19.31-4.5-20.49-6.74-.91-1.72,0-3.58.67-4.58H104.76C103.89,250.81,98.67,261.15,98.3,265.37Z"
          fill="#231f20"
        ></path>
        <path
          d="M349.2,266.31H50.8a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H349.2a.5.5,0,0,1,.5.5A.5.5,0,0,1,349.2,266.31Z"
          fill="#231f20"
        ></path>
      </g>
    </svg>
  );
};

export default TeamPresentation;
