import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Clock from "../../images/Clock";
import Location from "../../images/Location";
import Phone from "../../images/Phone";
import classes from "./Contact.module.css";
import Form from "./form/form";

const Contact = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={classes.body}>
      <h2 className={classes["section-title"]}>
        <span>Contacto</span>
      </h2>
      <div className="container mt-9">
        <div className="row gy-50">
          <div className="col-lg-4">
            <div
              className="d-flex animated"
              data-show="startbox"
              // style="transform: translateY(0px); transition-duration: 500ms; opacity: 1;"
            >
              <div className={`${classes["margin-top--40"]} "flex-shrink-0"`}>
                <Phone />
              </div>
              <div
                className={`${classes["margin-top--40"]}  ${classes["margin-left--15"]} "flex-grow-1 ms-15"`}
              >
                <h5 className="m-0">Información de contacto:</h5>
                <p className={`${classes["margin-top--15"]} "m-0 mt-15"`}>
                  Correo: betancesgroup@gmail.com <br />
                  Telefono: +1 (718) 294 - 5400 <br />
                  Fax: (718) 294 - 8700
                </p>
              </div>
            </div>
            <div
              className="d-flex mt-50 animated"
              data-show="startbox"
              data-show-delay="100"
              // style="transform: translateY(0px); transition-duration: 500ms; opacity: 1;"
            >
              <div className={`${classes["margin-top--40"]} "flex-shrink-0"`}>
                <Location />
              </div>
              <div
                className={`${classes["margin-top--40"]} ${classes["margin-left--15"]}  "flex-grow-1 ms-15"`}
              >
                <h5 className="m-0">Dirección:</h5>
                <p className={`${classes["margin-top--15"]} "m-0 mt-15"`}>
                  172E 174th Street, <br />
                  Bronx, NY 10457
                </p>
              </div>
            </div>
            <div
              className="d-flex mt-50 animated"
              data-show="startbox"
              data-show-delay="200"
              // style="transform: translateY(0px); transition-duration: 500ms; opacity: 1;"
            >
              <div className={`${classes["margin-top--40"]} "flex-shrink-0"`}>
                <Clock />
              </div>
              <div
                className={`${classes["margin-top--40"]} ${classes["margin-left--15"]} "flex-grow-1 ms-15"`}
              >
                <h5 className="m-0">Horario de apertura:</h5>
                <p className={`${classes["margin-top--15"]} "m-0 mt-15"`}>
                  Lunes - Sábado <br />
                  8:00 am - 8:00 pm <br />
                  Domingo <br />
                  9:00 am - 4:00 pm
                </p>
              </div>
            </div>
          </div>
          <Form />
        </div>
      </div>
      {/* // <div className={classes.body}>
    //   <h2 className={classes["section-title"]}>
    //     <span>Contacto</span>
    //   </h2>
    //   <div className={classes.card}>
    //     <div
    //       className={`${classes.column} ${classes["background-color-blue"]} ${classes["border-radius--20"]}`}
    //     >
    //       <div className={classes["info"]}>
    //         <div className="d-flex">
    //           <div className={classes["margin-top--30"]}>
    //             <Location />
    //           </div>
    //           <div className={classes["margin-left--10"]}>
    //             <h5>Dirección</h5>
    //           </div>
    //         </div>
    //         <p>
    //           174 EAST 172 STREET <br></br> BRONX, NEW YORK, 10468
    //         </p>
    //       </div>
    //       <div className={classes["info"]}>
    //         <div className="d-flex">
    //           <div className={classes["margin-top--30"]}>
    //             <Clock />
    //           </div>
    //           <div className={classes["margin-left--10"]}>
    //             <h5>Horario</h5>
    //           </div>
    //         </div>
    //         <p>
    //           Lunes - Sabados: 8:00am - 8pm (EST) <br></br>Domingos: 9:00am -
    //           5:00pm (EST)
    //         </p>
    //       </div>

    //       <div className={classes["info"]}>
    //         <div className="d-flex">
    //           <div className={classes["margin-top--30"]}>
    //             <Phone />
    //           </div>
    //           <div className={classes["margin-left--10"]}>
    //             <h5>Contacto</h5>
    //           </div>
    //         </div>
    //         <p>
    //           <span>Telephone: </span>+1 (123) 456-7890
    //         </p>
    //         <p>
    //           <span>Email: </span>betancesgroupcorp@gmail.com
    //         </p>
    //       </div>
    //     </div>
    //     <div className={classes["column-2"]}>
    //       <div
    //         className={`${classes["info"]} ${classes["text-center"]} ${classes["margin-bottom--10"]}`}
    //       ></div>
    //       <Form />
    //     </div>
    //   </div>
    // </div> */}
    </div>
  );
};

export default Contact;
