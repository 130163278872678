import ReactGA from "react-ga";

const useAnalyticsEventTracker = (category = "Contact Us") => {
  const eventTracker = (action = "email", label = "clicked") => {
    ReactGA.event({ category, action, label });
  };

  return eventTracker;
};

export default useAnalyticsEventTracker;
