import { useLocation, useNavigate } from "react-router-dom";
import { Fragment, useEffect } from "react";

import classes from "./Home.module.css";
import ServiceCard from "../cards/ServiceCard";
import CardWithImage from "../cards/CardWithImage";
import PiggyBank from "../../images/PiggyBank";
import Receptionist from "../../images/Receptionist";
import Motorcicle from "../../images/Motorcycle";
import TeamPresentation from "../../images/TeamPresentation";
import ClearBlueButton from "../UI/ClearBlueButton";

import BgLogoWhite from "../../images/Betances_Group_White_Logo";

const Home = () => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const routeChange = (route) => {
    navigate(route);
  };

  return (
    <Fragment>
      <div className={classes.lander}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className={classes["lander-info"]}>
                <div className={classes.title}>
                  <span>Confianza & Seguridad</span>
                </div>
                <div className={classes["under-title"]}>
                  <span>en cada servicio</span>
                </div>

                <div className={classes.message}>
                  <span>Más de una década ayudando a nuestra comunidad. </span>
                </div>
              </div>

              <div className={classes["lander-actions"]}>
                <button
                  className={classes["lander-servicios-btn"]}
                  onClick={() => routeChange("/servicios")}
                >
                  servicios
                </button>
                <button
                  className={classes["lander-contactanos-btn"]}
                  onClick={() => routeChange("/contacto")}
                >
                  contacto
                </button>
              </div>
            </div>
            <div className="col-lg-3">
              <div className={classes["lander-info"]}>
                <BgLogoWhite />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.body}>
        <div className={`${classes["section"]} ${classes["mgt-66"]}`}>
          <div className={classes["section-heading"]}>
            <h2 className={classes["section-title"]}>
              <span>Servicios</span>
            </h2>
            <div className={classes.services}>
              <ServiceCard
                iconClass="currency_exchange"
                header="transferencia de dinero"
                body="Hacemos envios de dinero de forma fácil y segura a destinatarios de todo el mundo"
              />
              <ServiceCard
                iconClass="attach_money"
                header="preparación de impuestos"
                body="Nuestros servicios le ayuda a cumplimentar y presentar sus impuestos de forma precisa y eficaz, ahorrándole tiempo y molestias  "
              />
              <ServiceCard
                iconClass="payments"
                header="pagos de facturas"
                body="Ofrecemos servicios de pagos de facturas a empresas como Claro, Con Edison, y más"
              />
            </div>
            <div
              className={`${classes["services-action"]} ${classes["marging-top--30"]}`}
            >
              <ClearBlueButton
                name={"ver más"}
                onClick={() => routeChange("/servicios")}
              />
            </div>
          </div>
        </div>
        <div className={`${classes["section"]} ${classes["mgt-66"]}`}>
          <div className={classes["section-heading"]}>
            <h2 className={classes["section-title"]}>
              Metodos de transferencia de <span>dinero</span>
            </h2>
            <div className={classes.services}>
              <CardWithImage
                Image={PiggyBank}
                header="depósito en cuenta bancaria"
                // body="Nuestros mensajeros hacen el deposito directamente a su cuenta bancaria"
                body="Entregamos su dinero directamente a su cuenta bancaria en América Latina"
              />
              <CardWithImage
                Image={Receptionist}
                header="recogida en oficina"
                body="Entregamos su dinero en nuestra oficina para que lo recoja en persona en América Latina"
              />
              <CardWithImage
                Image={Motorcicle}
                header="entrega a domicilio"
                body="Entregamos su dinero directamente a su hogar en América Latina"
              />
            </div>
          </div>
        </div>

        <div className={`${classes["section"]} ${classes["mgt-80-flex"]}`}>
          <div className={classes["flex-40-div"]}>
            <h2 className={classes["section-title"]}>
              <span>Solucion integrales: Tranferencias y mucho más!</span>
            </h2>
            {/* <div className={classes["section-heading"]}>
              <h1>Solucion integrales: Tranferencias y mucho más!</h1>
            </div> */}

            <div className="container">
              <div className="row">
                <div className={`col-lg-7 ${classes["box-body"]}`}>
                  Nuestros servicios van más allá de la mera transferencia de
                  dinero e incluyen toda una gama de servicios financieros que
                  pueden facilitarle la vida. Además de permitirle transferir
                  dinero de forma rápida y sencilla a amigos, familiares o
                  empresas, también le ofrecemos ayuda con la preparación de
                  impuestos, el pago de facturas, la traducción de documentos y
                  mucho más. Con nuestros servicios de notaría pública, puede
                  certificar documentos importantes ante notario, y nuestros
                  servicios de escaneado facilitan la digitalización y el
                  almacenamiento de documentos importantes. Tanto si necesita
                  ayuda con sus finanzas como si sólo quiere hacer su vida un
                  poco más fácil, estamos aquí para ayudarle.
                </div>
                <div className={`col-lg-5 ${classes["team-presentation"]}`}>
                  <TeamPresentation />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
