import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import ServiceCardItem from "../cards/ServiceCardItem";
import classes from "./services.module.css";
const services = [
  {
    name: "transferencia de dinero",
    description:
      "Hacemos envíos de dinero a mas de 100 diferente países a través del mundo",
    icon: "currency_exchange",
  },
  {
    name: "preparación de impuestos",
    description: "",
    icon: "attach_money",
  },
  { name: "venta de money order", description: "", icon: "finance_chip" },
  {
    name: "pagos de facturas",
    description:
      "pagos de varios tipos de facturas como Claro, Con Edison, tarjetas de credito, ect.",
    icon: "payments",
  },
  {
    name: "notario público",
    description: "",
    icon: "edit_square",
  },
  {
    name: "servicios de inmigración",
    description: "",
    icon: "travel_explore",
  },

  {
    name: "creación de empresas",
    description: "",
    icon: "add_business",
  },

  {
    name: "representantes de TLC",
    description: "",
    icon: "support_agent",
  },

  {
    name: "cartas de recomendación y de trabajo",
    description: "",
    icon: "mark_email_read",
  },

  {
    name: "divorcios",
    description: "",
    icon: "safety_divider",
  },
  {
    name: "quiebra",
    description: "",
    icon: "trending_down",
  },

  {
    name: "traducciones",
    description: "",
    icon: "translate",
  },
  {
    name: "servicio de impresora",
    description: "imprimimos documetos y imagenes",
    icon: "print",
  },
  {
    name: "servicio de escáner",
    description: "tranformamos documentos fisicos a digital",
    icon: "scanner",
  },
  {
    name: "pagos por teléfono",
    description: "",
    icon: "order_approve",
  },

  {
    name: "entrega de paquetes en México",
    description: "",
    icon: "local_shipping",
  },

  {
    name: "UPS access point",
    description: "",
    icon: "inventory_2",
  },
];

const Services = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const service_list = services.map((service, index) => (
    <div className="col-lg-3" key={index}>
      <ServiceCardItem
        iconClass={service.icon}
        header={service.name}
        body={service.description}
        id={index}
      />
    </div>
  ));
  return (
    <div className={"container"}>
      <h2 className={classes["section-title"]}>servicios</h2>
      <div className="row">{service_list}</div>;
    </div>
  );
};

export default Services;
