import classes from "./ServiceCardItem.module.css";

const ServiceCardItem = ({ iconClass, header, body }) => {
  return (
    <div className={classes.service}>
      <article className={classes["icon-box"]}>
        <div className={classes["box-top"]}>
          <div className={classes["box-icon"]}>
            <span className="material-symbols-outlined">{iconClass}</span>
          </div>
          <div className={classes["box-header"]}>
            <p>{header}</p>
          </div>
        </div>
      </article>
    </div>
  );
};

export default ServiceCardItem;
