const Motorcicle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 300"
      width="406"
      height="306"
      className="illustration styles_illustrationTablet__1DWOa"
    >
      <g id="_222_motorcycle_flatline" data-name="#222_motorcycle_flatline">
        <path
          d="M215.85,67.3s2.32,5.92,1.38,6.85-3.14-.3-3.14-.3"
          fill="#fff"
        ></path>
        <path
          d="M216.24,75a5.62,5.62,0,0,1-2.39-.7.5.5,0,0,1-.2-.68.5.5,0,0,1,.68-.19c.61.33,2,.87,2.55.37.35-.43-.35-3.38-1.49-6.3a.5.5,0,0,1,.93-.37c.73,1.87,2.33,6.34,1.26,7.38A1.8,1.8,0,0,1,216.24,75Z"
          fill="#231f20"
        ></path>
        <path
          d="M216.93,55.86c2.29,3.43.31,9-.52,12.76q-1.16,5.16-2.53,10.26c-.58,2.16-6.24,1-7.1.13l-5,12.66a35.59,35.59,0,0,1-6.62-4.15c-4.11-3.08-3.5-6.65-3.5-6.65,1.74-6.9,5.72-14.3,8.62-20.83a10.86,10.86,0,0,1,2.52-3.85,10.53,10.53,0,0,1,3.36-1.82c2.78-1,6-1.78,8.63-.39A5.67,5.67,0,0,1,216.93,55.86Z"
          fill="#fff"
        ></path>
        <path
          d="M201.78,92.17a.63.63,0,0,1-.19,0,36.79,36.79,0,0,1-6.73-4.22c-4.3-3.23-3.72-7-3.7-7.13,1.33-5.24,3.86-10.64,6.32-15.86.81-1.74,1.61-3.44,2.34-5.09a11.22,11.22,0,0,1,2.65-4A11.24,11.24,0,0,1,206,53.9c2.43-.92,6-2,9-.37a6.29,6.29,0,0,1,2.33,2h0c2.05,3.08.91,7.63,0,11.28-.17.66-.33,1.28-.45,1.86-.77,3.43-1.62,6.9-2.53,10.29a2.11,2.11,0,0,1-1.67,1.43,10,10,0,0,1-5.68-.69l-4.77,12.11a.57.57,0,0,1-.27.28A.69.69,0,0,1,201.78,92.17Zm9.7-38.46a14.77,14.77,0,0,0-5.15,1.13,10.64,10.64,0,0,0-3.2,1.72,10.18,10.18,0,0,0-2.39,3.69c-.74,1.65-1.54,3.36-2.36,5.1C196,70.53,193.44,75.89,192.14,81c0,.09-.47,3.27,3.32,6.12a40.41,40.41,0,0,0,6,3.87l4.8-12.16a.49.49,0,0,1,.37-.31.51.51,0,0,1,.45.14,8.58,8.58,0,0,0,5.31.81q.83-.23,1-.72c.91-3.38,1.76-6.83,2.52-10.24.13-.59.29-1.23.46-1.89.86-3.47,1.94-7.78.14-10.48h0a5.23,5.23,0,0,0-2-1.72A6.51,6.51,0,0,0,211.48,53.71Z"
          fill="#231f20"
        ></path>
        <path d="M214.35,76.29a4.14,4.14,0,0,1-4-3.56" fill="#fff"></path>
        <path
          d="M214.34,76.79a4.67,4.67,0,0,1-4.53-4,.5.5,0,0,1,.38-.6.49.49,0,0,1,.59.38,3.62,3.62,0,0,0,3.55,3.17.5.5,0,0,1,0,1Z"
          fill="#231f20"
        ></path>
        <path
          d="M206.78,79a9.44,9.44,0,0,1-3.68-3.84s-2.7,7,1.47,9.43Z"
          fill="#231f20"
        ></path>
        <path
          d="M204.57,85.1a.45.45,0,0,1-.25-.07c-4.48-2.66-1.8-9.74-1.68-10a.5.5,0,0,1,.93,0A8.89,8.89,0,0,0,207,78.57a.5.5,0,0,1,.22.62L205,84.78a.49.49,0,0,1-.31.29A.41.41,0,0,1,204.57,85.1Zm-1.34-8.65c-.5,1.84-1.13,5.54,1.11,7.38l1.82-4.61A10.74,10.74,0,0,1,203.23,76.45Z"
          fill="#231f20"
        ></path>
        <path
          d="M218.74,65.45c.1-.47-9.36-2.43-9.36-2.43s-1.43,1.17.37,3.6S217.75,70.25,218.74,65.45Z"
          fill="#231f20"
        ></path>
        <path
          d="M214.58,69.25a6.85,6.85,0,0,1-5.24-2.33c-2.05-2.78-.3-4.27-.28-4.28a.5.5,0,0,1,.42-.11c10,2.06,9.87,2.47,9.75,3h0a4.32,4.32,0,0,1-3.42,3.56A5.46,5.46,0,0,1,214.58,69.25Zm-5-5.68c-.22.34-.54,1.23.59,2.76a5.92,5.92,0,0,0,5.45,1.81,3.29,3.29,0,0,0,2.58-2.47C217.15,65.26,213.23,64.34,209.56,63.57Zm9.18,1.88h0Zm-.49-.1Z"
          fill="#231f20"
        ></path>
        <line
          x1="209.38"
          y1="63.02"
          x2="205.9"
          y2="62.21"
          fill="#231f20"
        ></line>
        <path
          d="M209.38,63.52h-.11l-3.48-.81a.5.5,0,1,1,.23-1l3.47.81a.5.5,0,0,1-.11,1Z"
          fill="#231f20"
        ></path>
        <path
          d="M217.66,61.13a3.5,3.5,0,0,0,1.62-1.92,3.63,3.63,0,0,0-.41-2.57c-1.65-3.26-5.7-4.77-9.32-4.31a9.28,9.28,0,0,0-4.48,1.73c-1.76,1.35-4.09,5.55-1.33,7.12a7.46,7.46,0,0,0,3.22.44c1.64.1,3.26.43,4.9.55A9.68,9.68,0,0,0,217.66,61.13Z"
          fill="#fff"
        ></path>
        <path
          d="M213.17,62.72c-.47,0-.92,0-1.35,0-.81-.06-1.62-.17-2.4-.27s-1.66-.23-2.49-.28l-.73,0a5.63,5.63,0,0,1-2.71-.48A2.91,2.91,0,0,1,202,59.2c-.14-2.1,1.53-4.57,2.8-5.53a9.7,9.7,0,0,1,4.72-1.84c4.14-.52,8.19,1.36,9.82,4.58a4.07,4.07,0,0,1,.45,2.93,4,4,0,0,1-1.84,2.22h0A9,9,0,0,1,213.17,62.72Zm-2.28-10a11.13,11.13,0,0,0-1.28.08,8.81,8.81,0,0,0-4.24,1.64c-1.08.83-2.51,3-2.4,4.67a1.88,1.88,0,0,0,1,1.62,4.88,4.88,0,0,0,2.22.35c.27,0,.53,0,.78,0,.86,0,1.73.16,2.56.28s1.56.2,2.34.26a9.3,9.3,0,0,0,5.51-1,3,3,0,0,0,1.4-1.63,3.13,3.13,0,0,0-.38-2.2A8.34,8.34,0,0,0,210.89,52.74Zm6.77,8.39h0Z"
          fill="#231f20"
        ></path>
        <path
          d="M201.27,74.31l.2-.11c3-1.75,4.1-5.77,4.31-8.72.14-2.07.81-5.37,2.35-7,1.89-2,11.88,2.16,11.88,2.16,3.25-14.23-17.9-21-27.45-13a19.18,19.18,0,0,0-6.33,15.76c.27,4.33,2.5,11.52,7.66,11.9a20.54,20.54,0,0,0,4.57-.18A8.77,8.77,0,0,0,201.27,74.31Z"
          fill="#fff"
        ></path>
        <path
          d="M195.38,75.87c-.51,0-1,0-1.52-.06-5.43-.39-7.84-7.6-8.13-12.36a19.81,19.81,0,0,1,6.5-16.17c6-5.05,15.8-4.25,21.85-.56,5.37,3.26,7.71,8.37,6.42,14a.48.48,0,0,1-.26.33.48.48,0,0,1-.42,0c-4-1.68-10.19-3.5-11.32-2.28-1.32,1.41-2.07,4.39-2.23,6.71-.1,1.58-.75,6.9-4.54,9.12l-.22.12a9.23,9.23,0,0,1-3,.88A20.82,20.82,0,0,1,195.38,75.87ZM203,44.71A15.49,15.49,0,0,0,192.88,48a18.79,18.79,0,0,0-6.15,15.35c.26,4.24,2.45,11.08,7.2,11.43a19.57,19.57,0,0,0,4.46-.18,8.34,8.34,0,0,0,2.65-.77h0l.18-.1c3.37-2,4-6.87,4.06-8.33.16-2.32.9-5.63,2.48-7.32,1.86-2,9.33.78,11.88,1.81.9-5-1.29-9.44-6.08-12.36A21,21,0,0,0,203,44.71Z"
          fill="#231f20"
        ></path>
        <path
          d="M198.61,71.89a.51.51,0,0,1-.46-.31c-.16-.37-3.75-9.11,1.4-16.69a8.1,8.1,0,0,1,8.56-3.56c3.9.67,7.65,3.47,8.15,5.35a.5.5,0,0,1-.36.61.5.5,0,0,1-.61-.35c-.37-1.39-3.69-4-7.35-4.62a7.17,7.17,0,0,0-7.56,3.13c-4.85,7.13-1.34,15.66-1.31,15.74a.5.5,0,0,1-.26.66A.71.71,0,0,1,198.61,71.89Z"
          // fill="#d1d3d4"
          fill="#00639d"
        ></path>
        <path
          d="M237.94,118.45a.51.51,0,0,1-.44-.25l-7.73-13.39a.5.5,0,1,1,.86-.5l7.74,13.39a.49.49,0,0,1-.18.68A.47.47,0,0,1,237.94,118.45Z"
          fill="#231f20"
        ></path>
        <path
          d="M228.22,109.42a.54.54,0,0,1-.36-.14.51.51,0,0,1,0-.71c.24-.42,2.11-6.07,3.23-9.52a.5.5,0,1,1,.95.31c-3.09,9.53-3.36,9.81-3.47,9.92A.52.52,0,0,1,228.22,109.42Z"
          fill="#231f20"
        ></path>
        <path
          d="M254.15,117.1s4.15,2.32,4.83,6.8c.92,6-2.27,8-2.27,8Z"
          fill="#231f20"
        ></path>
        <path
          d="M107.55,177s-3.69-1.3-5.32-.65S89.35,195.2,90.4,196.09s6.32,0,6.32,0Z"
          fill="#fff"
        ></path>
        <path
          d="M103.41,176.17a15.43,15.43,0,0,1,4.14.83L96.72,196.09a30.3,30.3,0,0,1-4.22.4,3.69,3.69,0,0,1-2.1-.4c-1.05-.89,10.19-19.09,11.83-19.74a3.22,3.22,0,0,1,1.18-.18m0-1a4.16,4.16,0,0,0-1.55.25c-2.18.86-12,17.37-12.5,20.1a1.33,1.33,0,0,0,.39,1.33,4.19,4.19,0,0,0,2.75.64,32.36,32.36,0,0,0,4.39-.41l.47-.08.23-.42,10.83-19.09.59-1-1.13-.4a16.58,16.58,0,0,0-4.47-.88Z"
          fill="#231f20"
        ></path>
        <circle cx="120.96" cy="229.38" r="26.41" fill="#231f20"></circle>
        <path
          d="M121,256.29a26.91,26.91,0,1,1,26.91-26.91A26.94,26.94,0,0,1,121,256.29Zm0-52.82a25.91,25.91,0,1,0,25.91,25.91A25.94,25.94,0,0,0,121,203.47Z"
          fill="#231f20"
        ></path>
        <circle cx="120.96" cy="229.38" r="18.67" fill="#fff"></circle>
        <path
          d="M121,210.71a18.68,18.68,0,1,1-18.68,18.67A18.67,18.67,0,0,1,121,210.71m0-1a19.68,19.68,0,1,0,19.67,19.67A19.7,19.7,0,0,0,121,209.71Z"
          fill="#231f20"
        ></path>
        {
          <circle
            cx="120.96"
            cy="229.38"
            r="7.41"
            // fill="#d1d3d4"
            // fill="#00639d"
            fill="#ff8c00"
          ></circle>
        }
        <path
          d="M128.37,206.25c-10.12,4.46-19.43,19.17-4.08,23.13,8.75,2.26,60.7,1,60.7,1l-11.8-21S138.22,201.9,128.37,206.25Z"
          fill="#231f20"
        ></path>
        <path
          d="M156,231.25c-13.54,0-27.59-.28-31.83-1.38-6.62-1.71-8.26-5.23-8.48-7.89-.5-6.17,6-13.35,12.48-16.19h0c9.88-4.37,43.7,2.85,45.13,3.16a.51.51,0,0,1,.33.24l11.8,21a.55.55,0,0,1,0,.5.53.53,0,0,1-.43.25C183.91,230.93,170.24,231.25,156,231.25Zm-18-25.86c-4,0-7.34.37-9.47,1.31h0c-6,2.65-12.34,9.54-11.88,15.2.27,3.34,2.94,5.76,7.73,7,8,2.06,53,1.17,59.73,1l-11.28-20.05C170.58,209.4,151.12,205.39,138,205.39Zm-9.67.86h0Z"
          fill="#231f20"
        ></path>
        <circle cx="283.27" cy="229.38" r="26.41" fill="#231f20"></circle>
        <path
          d="M283.27,256.29a26.91,26.91,0,1,1,26.91-26.91A26.94,26.94,0,0,1,283.27,256.29Zm0-52.82a25.91,25.91,0,1,0,25.91,25.91A25.95,25.95,0,0,0,283.27,203.47Z"
          fill="#231f20"
        ></path>
        <circle cx="283.27" cy="229.38" r="18.67" fill="#fff"></circle>
        <path
          d="M283.27,210.71a18.68,18.68,0,1,1-18.67,18.67,18.67,18.67,0,0,1,18.67-18.67m0-1A19.68,19.68,0,1,0,303,229.38a19.7,19.7,0,0,0-19.68-19.67Z"
          fill="#231f20"
        ></path>
        {
          <circle
            cx="283.27"
            cy="229.38"
            r="7.41"
            // fill="#d1d3d4"
            // fill="#00639d"
            fill="#ff8c00"
          ></circle>
        }
        <path
          d="M249.34,136.89l-14.87,8.33s10.11,48.19,9.22,53.25-3,8.33-6.84,8.63-31.54,0-37.49,0,9.46-41.35,9.46-41.35H129.15s-16.06,3.57-25.58,15.47S88.31,216,90.4,217.8s81.53.77,81.53.77l8.39,12.92H244c9.52,0,19.93-26.47,19.93-26.47H310s-5.75-13.19-18.54-16.46-18.35-2.88-18.35-2.88,6.25-1.49,6.25-7.44S259.16,142.54,249.34,136.89Z"
          // fill="#68e1fd"
          fill="#00639d"
        ></path>
        <path
          d="M126.7,166.42S114,156.23,121,153.55s82.15-3.27,87.86,2.38,4.23,11.23-1,12.52S126.7,166.42,126.7,166.42Z"
          fill="#231f20"
        ></path>
        <path
          d="M236.55,146.56c-.6-.15-11.47-11.75-11.46-19.49s6.65-11.75,13.14-11A95.27,95.27,0,0,0,254,116l3,17.19L245.32,135l3.87,4.47Z"
          // fill="#68e1fd"
          fill="#00639d"
        ></path>
        <path
          d="M194.15,136.06c-1-.2-35.94-4.79-35.94-4.79s-12.42,22.16-.12,27,55.7,3.88,55.7,3.88L206.2,200h16.27s15.66-39.27,11.3-49.19-42.42-11.07-42.42-11.07Z"
          fill="#231f20"
        ></path>
        <path
          d="M154.64,136.89s10.9-31.34,19-45.42c3.69-6.4,9.76-12.46,17.6-12.62,3.47-.07,6.41.42,9.07,2.73a22.57,22.57,0,0,1,7.2,11.82c2.27,9.65-11.87,42.17-14,47.65Z"
          // fill="#68e1fd"
          fill="#00639d"
        ></path>
        <path
          d="M216.32,122s13.68.4,15.67.6,5.75,3.48,5.25,5.31-5.66-.12-7.14-.85-14.56,1.61-14.56,1.61Z"
          fill="#fff"
        ></path>
        <path
          d="M216.32,122s13.68.4,15.67.6,5.75,3.48,5.25,5.31c-.15.55-.73.76-1.5.76a16.51,16.51,0,0,1-5.64-1.61,3.13,3.13,0,0,0-1.17-.14c-3.61,0-13.39,1.75-13.39,1.75l.78-6.67m-.89-1-.11.91-.78,6.66-.16,1.34,1.33-.23c.1,0,9.74-1.74,13.22-1.74a3.62,3.62,0,0,1,.77,0,17.06,17.06,0,0,0,6,1.7c1.79,0,2.32-.94,2.47-1.5a3.46,3.46,0,0,0-.75-2.83c-1.14-1.66-3.57-3.56-5.37-3.74-2-.2-15.18-.58-15.74-.6l-.92,0Z"
          fill="#231f20"
        ></path>
        <path
          d="M170.5,214.74a.46.46,0,0,1-.29-.1.5.5,0,0,1-.11-.7c.17-.23,16.8-23.17,2.26-35.42-12.12-10.21-40.87-12.3-58.37.42C100,189.07,96.46,212.37,96.42,212.61a.5.5,0,1,1-1-.15A72.56,72.56,0,0,1,99,199.25c2.44-6.63,6.93-15.68,14.41-21.12C128,167.52,157.85,165,173,177.76s-1.93,36.54-2.1,36.77A.49.49,0,0,1,170.5,214.74Z"
          fill="#231f20"
        ></path>
        <path
          d="M198.2,202H178.65a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H198.2a.5.5,0,0,1,.5.5A.5.5,0,0,1,198.2,202Z"
          fill="#231f20"
        ></path>
        <path
          d="M239.82,224.26H183.59a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5h56.23c2.65,0,7.61-5,13-13.12,8.3-12.61,15.35-29.24,12.77-35.27-2.24-5.22-6-7.78-9.72-10.26s-7.09-4.76-9-9.38a22.7,22.7,0,0,1-.57-17.06.5.5,0,1,1,.91.41,22.16,22.16,0,0,0,.58,16.26c1.82,4.35,5.14,6.58,8.66,8.95,3.8,2.55,7.73,5.2,10.08,10.68,2.82,6.58-4.52,23.57-12.86,36.22C250.91,214.77,244.18,224.26,239.82,224.26Z"
          fill="#231f20"
        ></path>
        <path
          d="M241.85,232.16c-1,0-2.23,0-3.62-.06-1.88,0-4.22-.11-7-.11H205a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5h26.18c2.83,0,5.18.06,7.07.11,8.76.22,9.28.23,14.22-6.12,6.11-7.85,18.39-36.33,18.52-36.62a.5.5,0,1,1,.91.4c-.5,1.17-12.46,28.88-18.64,36.83C248.84,231.29,247.49,232.16,241.85,232.16Z"
          fill="#231f20"
        ></path>
        <path
          d="M206.2,200s-4.31,8.92-1.78,9.52,34.68,4.05,32.13.63S222.47,200,222.47,200Z"
          fill="#fff"
        ></path>
        <path
          d="M230,212.09A228.2,228.2,0,0,1,204.3,210a1.38,1.38,0,0,1-1-.78c-1-2.18,2.05-8.7,2.4-9.44a.51.51,0,0,1,.45-.28h16.27a.45.45,0,0,1,.25.07c.47.27,11.64,6.82,14.23,10.28A1,1,0,0,1,237,211C236.5,211.79,233.74,212.09,230,212.09Zm-23.5-11.63c-1.25,2.65-2.88,7-2.27,8.29a.39.39,0,0,0,.28.24c3.46.82,30.35,3.36,31.68,1.43-2.39-3.13-12.67-9.25-13.88-10Z"
          fill="#231f20"
        ></path>
        <path
          d="M196.53,91.25l-1.82,27,24.63,1,1,12.49s-33.51,1.5-40.46-4.21S177.49,94,181.26,90.06,197.11,82.78,196.53,91.25Z"
          // fill="#68e1fd"
          fill="#00639d"
        ></path>
        <path
          d="M147,190.56H118.64a.5.5,0,1,1,0-1H147a.5.5,0,0,1,0,1Z"
          fill="#231f20"
        ></path>
        <path
          d="M144.53,194.31H116.17a.5.5,0,0,1,0-1h28.36a.5.5,0,1,1,0,1Z"
          fill="#231f20"
        ></path>
        <path
          d="M142.05,198.06H113.69a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5h28.36a.5.5,0,0,1,.5.5A.5.5,0,0,1,142.05,198.06Z"
          fill="#231f20"
        ></path>
        <path
          d="M139.58,201.81H111.22a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h28.36a.5.5,0,0,1,.5.5A.5.5,0,0,1,139.58,201.81Z"
          fill="#231f20"
        ></path>
        <path
          d="M234.47,145.72a.49.49,0,0,1-.44-.26.5.5,0,0,1,.19-.68l14.88-8.33a.51.51,0,0,1,.68.19.5.5,0,0,1-.2.68l-14.87,8.33A.44.44,0,0,1,234.47,145.72Z"
          fill="#231f20"
        ></path>
        <circle cx="231.56" cy="127.92" r="3.35" fill="#231f20"></circle>
        <path
          d="M213.05,119.46h0l-18.34-.74a.48.48,0,0,1-.35-.16.53.53,0,0,1-.13-.36l1.67-29a.5.5,0,1,1,1,.06l-1.64,28.54,17.83.71a.51.51,0,0,1,.48.52A.5.5,0,0,1,213.05,119.46Z"
          fill="#231f20"
        ></path>
        <path
          d="M201,132.42c-8.95,0-19.16-.88-21.59-4.65-4-6.2-3.77-24.21-3.69-31,0-.65,0-1.17,0-1.54a.5.5,0,1,1,1,0c0,.37,0,.9,0,1.55-.07,6.06-.28,24.49,3.54,30.44,1.67,2.6,8.25,4,19,4.17,8.25.1,16.09-.62,16.17-.63a.5.5,0,1,1,.09,1C215,131.82,208.45,132.42,201,132.42Z"
          fill="#231f20"
        ></path>
        <path
          d="M208.1,82.77S190.83,74,189.25,76.32A40.55,40.55,0,0,0,186.18,82s17.43,8.73,20.26,6.35A5.14,5.14,0,0,0,208.1,82.77Z"
          // fill="#68e1fd"
          fill="#00639d"
        ></path>
        <path
          d="M205.33,162.69c-9.71,0-34-.19-42.82-2-9.63-2-9.52-4.83-9.46-6.19,0-.12,0-.27,0-.32a.5.5,0,0,1,.23-.66.52.52,0,0,1,.68.24,1.68,1.68,0,0,1,.09.78c0,1-.14,3.33,8.66,5.17,10.8,2.26,46.31,2,46.67,2a.49.49,0,0,1,.5.5.5.5,0,0,1-.5.5Z"
          fill="#fff"
        ></path>
      </g>
    </svg>
  );
};

export default Motorcicle;
